import React from "react";
import MyListingCard from "../components/Dashboard/listing/MyListingCard";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserListing } from "../services/operations/listingOperations";
import { useEffect, useState } from "react";
import {
  hideProperty,
  unhideProperty,
  deleteListing,
} from "../services/operations/listingOperations";

export default function MyListing() {
  const { user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);

  useEffect(() => {
    const fetchListing = async () => {
      const result = await getUserListing(token);
      if (result) {
        setListings(result || []);
      }
    };
    fetchListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  const handleDelete = async (listingId, navigate) => {
    try {
      const response = await deleteListing(listingId, token);
      if (!response?.data?.success) {
        throw new Error("faliled to delete");
      }
      console.log(response);
      const result = await getUserListing(token);
      setListings(result || []);

      navigate("/dashboard/my-listing");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="container mx-auto ">
        <h1 className="text-2xl font-bold mb-6">My Listings</h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 ">
          {listings.map((listing, index) => {
            return (
              <div>
                <MyListingCard
                  key={listing._id}
                  listingId={listing._id}
                  offerfor={listing.offer_type}
                  index={index}
                  locality={listing.locality}
                  city={listing.city}
                  price={listing.rent}
                  type={listing.property_type}
                  userimage={user?.image}
                  user={user?.firstName}
                  images={listing.images}
                  hide={listing.hide}
                  handleDelete={handleDelete}
                  link={`/user-listing-details/${listing._id}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
