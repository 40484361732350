import React from 'react'
import Footer from '../components/common/Footer'
import { Helmet } from 'react-helmet';


export default function About() {
  return (
    <div>
      <Helmet>
      <title>About Room Easy: Your Trusted Partner for Shared Accommodation</title>
      <meta name="description" content="Learn more about Room Easy, the leading platform for finding roommates and living spaces in India. Discover our mission, features, and team." />
      <meta name="keywords" content="room easy, about room easy, roomeasy, about roomeasy, shared accommodation, roommate matching, India, student housing, bachelor accommodation" />
    </Helmet>
      <div className="mx-auto w-full bg-zinc-300 h-[2px] pt-1"></div>
       <div className="container mx-auto px-4 my-8 max-w-7xl">
      
      <h1 className="text-4xl font-bold mb-6">Room Easy: Your One-Stop Shop for Finding the Perfect Roommate and Living Space in India</h1>
      
      <p className="mb-4">
        <strong>Room Easy</strong> (<a href="https://roomeasy.net" className="text-blue-600">roomeasy.net</a>) is revolutionizing the way bachelors in India find shared accommodation. We are more than just a room finder; we're your gateway to a seamless and stress-free housing experience. Whether you're a student seeking a comfortable PG, a young professional looking for a flatmate, or an individual transitioning to a new city, Room Easy has you covered.
      </p>

      <h2 className="text-3xl font-semibold mt-6 mb-4">What Makes Us Different?</h2>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Extensive Room Listings:</strong> We boast a comprehensive database of rooms, flats, PGs, and hostels across India. Our user-friendly platform allows you to refine your search based on location, budget, amenities (think high-speed Wi-Fi, laundry facilities, fitness centers), and even flat type.</li>
        <li><strong>Hassle-Free Communication:</strong> Connect directly with potential roommates and landlords through our secure messaging system. Schedule viewings, ask questions, and clarify details – all within the comfort of the Room Easy platform.</li>
        <li><strong>Community Forum:</strong> Our vibrant online community allows you to connect with fellow bachelors, share experiences, seek advice, and navigate the exciting (and sometimes challenging) world of shared living in India.</li>
      </ul>

      <h2 className="text-3xl font-semibold mt-6 mb-4">Benefits of Using Room Easy.net</h2>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Save Time and Effort:</strong> Ditch the endless scrolling and impersonal interactions. Room Easy streamlines the room-finding process, connecting you with compatible roommates and suitable living spaces that meet your needs.</li>
        <li><strong>Peace of Mind:</strong> Our verified listings and secure payment gateway ensure a safe and reliable experience. Focus on finding the perfect place, and leave the security worries to us.</li>
        <li><strong>Build Lasting Connections:</strong> Room Easy isn't just about finding a room; it's about finding your perfect match. Imagine discovering a roommate who becomes a friend, confidante, and maybe even a lifelong connection.</li>
      </ul>

      <h2 className="text-3xl font-semibold mt-6 mb-4">Ready to Find Your Perfect Roommate and Living Space?</h2>
      <p className="mb-4">
        <strong>Join Room Easy today!</strong> Simply sign up for a free account and start browsing through our extensive listings. Upgrade to our premium subscription for an even more personalized and convenient experience, with priority access to new listings and detailed property information.
      </p>

      <p className="text-2xl font-bold mt-6">Room Easy (<a href="https://roomeasy.net" className="text-blue-600">roomeasy.net</a>) – Your hassle-free solution for finding the perfect room, roommate, and living space in India.</p>

     </div>
     <Footer/>
    </div>
   

  )
}
