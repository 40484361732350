

export const sidebarLinks = [
  {
    id: 1,
    name: "My Profile",
    path: "/dashboard/my-profile",
    icon: "VscAccount",
  },
 
  {
    id: 2,
    name: "My Listings",
    path: "/dashboard/my-listing",
  
    icon: "VscVm",
  },
  {
    id: 3,
    name: "Add Listing",
    path: "/dashboard/create-listing",
   
    icon: "VscAdd",
  },
  {
    id: 4,
    name: "Hidden Listing",
    path: "/dashboard/hidden-listing",
   
    icon: "VscVm",
  },
  {
    id: 5,
    name: "Settings",
    path: "/dashboard/settings",
   
    icon: "VscSettingsGear",
  },

]
