import { combineReducers } from "@reduxjs/toolkit"

import authReducer from "../slices/authSlice"
import listingReducer from "../slices/listingSlice"
import profileReducer from "../slices/profileSlice"

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  listing: listingReducer,

})

export default rootReducer
