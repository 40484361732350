    import React from "react";

function CityCard({ name, properties, image, isWide }) {
  return (
    <article
      className={`flex flex-col self-stretch my-auto min-w-[240px] ${
        isWide ? "w-[799px]" : "w-[377px]"
      } max-md:max-w-full`}
    >
      <div
        className={`flex relative flex-col items-center px-20 pt-72 pb-9 w-full rounded-xl ${
          isWide ? "min-h-[369px]" : "aspect-[1.022]"
        } max-md:px-5 max-md:pt-24 max-md:max-w-full transition-transform transform hover:scale- hover:shadow-lg`}
      >
        <img
          loading="lazy"
          src={image}
          alt={`City view of ${name}`}
          className="object-cover absolute inset-0 size-full rounded-xl"
        />
        <div className="flex overflow-hidden relative flex-col justify-center items-center px-1.5 py-2.5 rounded bg-gradient-to-t from-black via-transparent to-black bg-opacity-50 min-h-[53px] w-[99px]">
          <div className="flex flex-col items-center text-white">
            <h2 className="text-xl font-semibold">{name}</h2>
            <p className="mt-2.5 text-base">{properties} Properties</p>
          </div>
        </div>
      </div>
    </article>
  );
}

export default CityCard;
