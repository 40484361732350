import React from "react";
import logo from "../../res/logo.jpg" 

function FooterImage() {
  return (
    <img
      loading="lazy"
      src={logo}
      className="object-contain mt-20 w-full aspect-[1000] max-md:mt-10 max-md:max-w-full"
      alt="Footer Illustration"
    />
  );
}

export default FooterImage;
