import React from 'react';
import { Link } from 'react-router-dom';

const links = ['About', 'Careers', 'Press', 'Customer Care', 'Services'];

function FooterLinks() {
  return (
    <nav className="flex gap-5 self-start mt-3.5 text-white">
      {links.map((link, index) => (
        <Link key={index} to={`/`} className={index === 3 ? "flex-auto" : ""}>
          {link}
        </Link>
      ))}
    </nav>
  );
}

export default FooterLinks;
