import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import locationData from "../data/locationData";
import {
  fetchListing,
  updateListing,
} from "../services/operations/listingOperations";

export default function UpdateListing() {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(null);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  const [localities, setLocalities] = useState([]);
  const [listing, setListing] = useState({
    title: "",
    description: "",
    offer_type: "",
    property_type: "",
    apartment_style: "",
    bathrooms: "",
    furnishing_status: "",
    available_from: "",
    rent: "",
    deposit: "",
    Lease_duration: "",
    phone: "",
    whatsapp_number: "",
    House_no: "",
    apartment: "",
    state: "",
    city: "",
    locality: "",
    pinCode: "",
  });

  const { listingId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm();

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity("");
    setCities(Object.keys(locationData[state] || {}));
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
    setLocalities(locationData[selectedState][city] || []);
  };

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const res = await fetchListing(listingId);
        setListing(res); // Set property data
        console.log("Fetched property details:", res);
      } catch (error) {
        console.error("Could not fetch listing details:", error);
      }

      setLoading(false);
    };
    fetchProperty();
  }, [listingId, navigate]);

  if (!listing) {
    return <div>No Listing Selected</div>;
  }

  const onSubmit = async (data) => {
    setLoading(true);
    setServerError(null);
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("offer_type", data.offer_type);
    formData.append("property_type", data.property_type);
    formData.append("apartment_style", data.apartment_style);
    formData.append("bathrooms", data.bathrooms);
    formData.append("furnishing_status", data.furnishing_status);
    formData.append("available_from", data.available_from);
    formData.append("rent", data.rent);
    formData.append("deposit", data.deposit);
    formData.append("Lease_duration", data.Lease_duration);
    formData.append("phone", data.phone);
    formData.append("whatsapp_number", data.whatsapp_number);
    formData.append("House_no", data.House_no);
    formData.append("apartment", data.apartment);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("locality", data.locality);
    formData.append("pinCode", data.pinCode);

    try {
      const response = await updateListing(listingId, formData, token);
      if (response.success) {
        navigate(`/listings/${listingId}`);
      } else {
        setServerError(response.message);
      }
    } catch (error) {
      console.error("Failed to update listing:", error);
      setServerError("An error occurred while updating the listing.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <main className="max-w-4xl mx-auto p-5">
        <h1 className="text-3xl font-bold text-center mb-10">
          List Your Property
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {/* Property Title */}
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Property Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter the property title"
                {...register("title", { required: true })}
                defaultValue={listing?.title}
              />
            </div>

            {/* Property Description */}
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Property Description
              </label>
              <textarea
                id="description"
                name="description"
                rows="4"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter the property description"
                {...register("description", { required: true })}
                defaultValue={listing?.description}
              ></textarea>
            </div>

            {/* Offer Type */}
            <div>
              <label className="block text-sm font-medium">Offerd For</label>
              <select
                name="offer_type"
                defaultValue={listing.offer_type}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("offer_type", { required: true })}
              >
                <option value="">select</option>
                <option value="Girls">Girls</option>
                <option value="Boys">"Boys"</option>
                <option value="Boys/Girls">Boys/Girls</option>
                <option value="Family">Family</option>
              </select>
              {formErrors.offer_type && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.offer_type}
                </p>
              )}
            </div>

            {/* Property Type */}
            <div>
              <label className="block text-sm font-medium">Property Type</label>
              <select
                name="property_type"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("property_type", { required: true })}
                defaultValue={listing.property_type}
              >
                <option value="">select</option>
                <option value="Flat">Flat</option>
                <option value="Hostel">Hostel</option>
                <option value="PG">PG</option>
              </select>
              {formErrors.property_type && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.property_type}
                </p>
              )}
            </div>

            {/* Apartment Style */}
            <div>
              <label className="block text-sm font-medium">
                Apartment Type
              </label>
              <select
                name="apartment_style"
                // value={apartment_style}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("apartment_style", { required: true })}
                defaultValue={listing?.apartment_style}
              >
                <option value="">select</option>
                <option value="1BHK">1BHK</option>
                <option value="2BHK">2BHK</option>
                <option value="1RK">1RK</option>
                <option value="1Room">Room</option>
                <option value="Hostel">Shared Room</option>
              </select>
              {formErrors.apartment_style && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.apartment_style}
                </p>
              )}
            </div>

            {/* Bathrooms */}
            <div>
              <label className="block text-sm font-medium">Bathroom</label>
              <select
                name="bathrooms"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("bathrooms", { required: true })}
                defaultValue={listing?.bathrooms}
              >
                <option value="">select</option>
                <option value="Common">Common</option>
                <option value="Attached">Attached</option>
              </select>
              {formErrors.bathrooms && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.bathrooms}
                </p>
              )}
            </div>

            {/* Furnishing Status */}
            <div>
              <label className="block text-sm font-medium">
                Furnishing Status
              </label>
              <select
                name="furnishing_status"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("furnishing_status", { required: true })}
                defaultValue={listing?.furnishing_status}
              >
                <option value="">select</option>
                <option value="Furnished">Furnished</option>
                <option value="Semi Furnished">Semi Furnished</option>
                <option value="Unfurnished">Unfurnished</option>
              </select>
              {formErrors.furnishing_status && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.furnishing_status}
                </p>
              )}
            </div>

            {/* Available From */}
            <div>
              <label className="block text-sm font-medium">
                Available From
              </label>
              <input
                name="available_from"
                type="date"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("available_from", { required: true })}
                defaultValue={listing?.available_from}
              />
              {formErrors.available_from && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.available_from}
                </p>
              )}
            </div>

            {/* Rent */}
            <div>
              <label className="block text-sm font-medium">Rent (₹)</label>
              <input
                name="rent"
                type="number"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("rent", { required: true })}
                defaultValue={listing?.rent}
              />
              {formErrors.rent && (
                <p className="text-red-500 text-sm mt-1">{formErrors.rent}</p>
              )}
            </div>

            {/* Deposit */}
            <div>
              <label className="block text-sm font-medium">Deposit (₹)</label>
              <input
                name="deposit"
                type="number"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("deposit", { required: true })}
                defaultValue={listing?.deposit}
              />
              {formErrors.deposit && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.deposit}
                </p>
              )}
            </div>

            {/* Lease Duration */}
            <div>
              <label className="block text-sm font-medium">
                Lease Duration (Months)
              </label>
              <input
                name="Lease_duration"
                type="number"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("Lease_duration", { required: true })}
                defaultValue={listing?.Lease_duration}
              />
              {formErrors.Lease_duration && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.Lease_duration}
                </p>
              )}
            </div>

            {/* Phone */}
            <div>
              <label className="block text-sm font-medium">Phone</label>
              <input
                name="phone"
                type="tel"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("phone", { required: true })}
                defaultValue={listing?.phone}
              />
              {formErrors.phone && (
                <p className="text-red-500 text-sm mt-1">{formErrors.phone}</p>
              )}
            </div>

            {/* WhatsApp Number */}
            <div>
              <label className="block text-sm font-medium">
                WhatsApp Number
              </label>
              <input
                name="whatsapp_number"
                type="tel"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("whatsapp_number", { required: true })}
                defaultValue={listing?.whatsapp_number}
              />
              {formErrors.whatsapp_number && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.whatsapp_number}
                </p>
              )}
            </div>

            {/* House No */}
            <div>
              <label className="block text-sm font-medium">House Number</label>
              <input
                name="House_no"
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("House_no", { required: true })}
                defaultValue={listing?.House_no}
              />
              {formErrors.House_no && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.House_no}
                </p>
              )}
            </div>

            {/* Apartment */}
            <div>
              <label className="block text-sm font-medium">Apartment</label>
              <input
                name="apartment"
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("apartment", { required: true })}
                defaultValue={listing?.apartment}
              />
              {formErrors.apartment && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.apartment}
                </p>
              )}
            </div>

            {/* State */}
            <div>
              <label className="block text-sm font-medium">State</label>
              <select
                name="state"
                onChange={handleStateChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("state", { required: true })}
                defaultValue={listing?.state}
              >
                <option value="">select </option>
                {Object.keys(locationData).map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              {formErrors.state && (
                <p className="text-red-500 text-sm mt-1">{formErrors.state}</p>
              )}
            </div>

            {/* City */}
            <div>
              <label className="block text-sm font-medium">City</label>
              <select
                name="city"
                onChange={handleCityChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("city", { required: true })}
                defaultValue={listing?.city}
              >
                <option value="">select </option>
                {cities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </select>

              {formErrors.city && (
                <p className="text-red-500 text-sm mt-1">{formErrors.city}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium">Locality</label>
              <select
                name="locality"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("locality", { required: true })}
                defaultValue={listing?.locality}
              >
                <option value="">select </option>
                {localities.map((locality) => {
                  return (
                    <option key={locality} value={locality}>
                      {locality}
                    </option>
                  );
                })}
              </select>
              {formErrors.state && (
                <p className="text-red-500 text-sm mt-1">{formErrors.state}</p>
              )}
            </div>
            {/* Pin Code */}
            <div>
              <label className="block text-sm font-medium">Pin Code</label>
              <input
                name="pinCode"
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                {...register("pinCode", { required: true })}
                defaultValue={listing?.pinCode}
              />
              {formErrors.pinCode && (
                <p className="text-red-500 text-sm mt-1">
                  {formErrors.pinCode}
                </p>
              )}
            </div>

            {/* Amenities
          <div className="sm:col-span-2">
            <label className="block text-sm font-medium">Amenities</label>
            <div className="mt-1 grid grid-cols-2 sm:grid-cols-3 gap-2">
              {["Water Purifier", "Gym", "Parking", "WiFi", "Security", "AC","Washing Machine","Cleaner","Water Heater/Solar","cot","bed","table"].map(
                (amenity) => (
                  <div key={amenity} className="flex items-center">
                    <input
                      name={amenity}
                      type="checkbox"
                      checked={amenities.includes(amenity)}
                      onChange={handleCheckboxChange}
                      className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <label htmlFor={amenity} className="ml-2 text-sm">
                      {amenity}
                    </label>
                  </div>
                )
              )}
            </div>
            {formErrors.amenities && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.amenities}
              </p>
            )}
          </div> */}

            {/* images
          <div>
            <div
              {...getRootProps()}
              className="border-dashed border-4 border-gray-400 p-6 text-center mb-4"
            >
              <div className="text-red-300">Images Must Not Contain Name or Contact Info</div>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>

            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
              {uploadedImages.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Uploaded file ${index + 1}`}
                  className="w-full h-auto object-cover"
                />
              ))}
            </div>
          </div> */}

            {/* Submit Button */}
            <div className="flex justify-center mx-auto">
              <button
                type="submit"
                className="px-4 py-2 mx-auto bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700"
                disabled={loading}
              >
                {loading ? "Creating..." : "Create Property"}
              </button>
            </div>

            {/* Error Message */}
            {/* {error && (
            <div className="text-red-600 text-center mt-4">{error}</div>
          )} */}
          </div>
        </form>
      </main>
    </div>
  );
}
