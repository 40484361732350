import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { searchListing } from "../services/operations/listingOperations";
import PropertyCard from "../components/Dashboard/listing/PropertyCard";
import Footer from "../components/common/Footer";

export default function Lisitng() {
  const location = useLocation();
  const [results, setResults] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const data = JSON.stringify(Object.fromEntries(queryParams.entries()));
  const query = JSON.parse(data);
  useEffect(() => {
    const fetchData = async () => {
      const response = await searchListing(query);
      // console.log("this is responde ", response);
      setResults(response || []);
    };

    fetchData();
  }, [location]);

  return (
    <div className="">
      <h1 className="text-2xl font-bold mb-4">Search Results</h1>
      <div className="m-9"> 
        {results ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {results.map((listing, index) => (
              <PropertyCard
                key={listing._id}
                offerfor={listing.offer_type}
                index={index}
                locality={listing.locality}
                city={listing.city}
                price={listing.rent}
                type={listing.property_type}
                userimage={listing?.image}
                user={listing?.user?.firstName}
                images={listing.images}
                link={`/listing-details/${listing._id}`}
                listing={listing}
              />
            ))}
          </div>
        ) : (
          <p>No properties found matching your criteria.</p>
        )}
      </div>
      <Footer />
    </div>
  );
}
