import React from "react";
import { useEffect, useState } from "react";
import Search from "../components/Search";
import PropSlider from "../components/PropSlider";
import CityCard from "../components/CityCard";
import FeatureCard from "../components/FeatureCard";
import Footer from "../components/common/Footer";
import reviews from "../data/data";
import Testimonial from "../components/Testimonial";
import featureData from "../data/featureData";
import citiesData from "../data/citiesData";

export default function Home() {
  return (
    <div>
      {/*  hero-section search bar */}
      <Search />

      {/* property slider */}
      <PropSlider />

      {/* listed cities section */}
      <section className="flex flex-col justify-center items-center bg-white min-h-[984px]">
        <header className="flex flex-col justify-center items-center text-neutral-600 max-md:max-w-full">
          <h1 className="text-2xl font-semibold max-md:max-w-full">
            Find The Best Properties at Great Prices
          </h1>
        </header>
        <main className="flex flex-col justify-center items-center mt-12 text-white max-md:mt-10 max-md:max-w-full">
          <div className="flex m-2 flex-wrap gap-5 justify-center items-center max-md:max-w-full overflow-x-hidden">
            {citiesData.slice(0, 2).map((city, index) => (
              <CityCard
                key={city.name}
                name={city.name}
                image={city.image}
                isWide={index === 1}
              />
            ))}
          </div>
          <div className="flex m-2 flex-wrap gap-5 justify-center items-center mt-9 max-md:max-w-full">
            {citiesData.slice(2).map((city, index) => (
              <CityCard
                key={city.name}
                name={city.name}
                image={city.image}
                isWide={index === 0}
              />
            ))}
          </div>
        </main>
      </section>

      {/* choose us section */}
      <section className="flex flex-col justify-center items-center bg-gradient-to-r from-blue-50 via-white to-blue-50 min-h-[581px] py-10">
        <header className="flex flex-col items-center text-gray-800">
          <h2 className="text-4xl font-semibold mb-2">Why Choose Us</h2>
          <p className="mt-3 text-lg">Because We are the Best</p>
        </header>
        <div className="flex flex-wrap gap-8 justify-center items-start mt-10 max-md:mt-8 max-md:max-w-full">
          {featureData.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </section>

      {/* testemonial slider */}
      <div className="flex flex-col w-[100vw] h-[100vh] justify-center items-center bg-gray-200">
        <div className="text-center">
          <h1 className="text-4xl font-bold">Our Testimonial</h1>
          <div className="bg-text-sky-500 h-[4px] w-1/5 mt-1 mx-auto relative"></div>
          <Testimonial reviews={reviews} />
        </div>
      </div>

      {/* footer section  */}
      <Footer />
    </div>
  );
}
