const citiesData = [
    {
      name: "Pune",
    
      image:
        "https://res.cloudinary.com/dsx0kergo/image/upload/v1725212088/Pune_fgnq5b.jpg",
    },
    {
      name: "Mumbai",
      
      image:
        "https://res.cloudinary.com/dsx0kergo/image/upload/v1725212090/Mumbai_dlbrkd.jpg",
    },
    {
      name: "Delhi",
      
      image:
        "https://res.cloudinary.com/dsx0kergo/image/upload/v1725212090/Delhi_buhzhv.jpg",
    },
    {
      name: "Banglore",
      
      image:
        "https://res.cloudinary.com/dsx0kergo/image/upload/v1725213104/UB_City_at_night__1_yse0o2.jpg",
    },
  ];
  export default citiesData;