import React, { useState } from 'react';

function NewsletterSubscription() {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Functionality to send the email
    console.log('Sending email to:', email);
    // Reset the email state
    setEmail('');
  };

  return (
    <form className="flex gap-2" onSubmit={handleSubmit}>
      <label htmlFor="email" className="sr-only">Your email address</label>
      <input
        type="email"
        id="email"
        className="grow px-4 py-3.5 bg-white rounded-lg text-neutral-400 w-fit max-md:pr-5"
        placeholder="Your email address…"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button type="submit" className="px-6 py-2 bg-blue-500 rounded-lg text-white max-md:px-5">
        Subscribe
      </button>
    </form>
  );
}

export default NewsletterSubscription;
