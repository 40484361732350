import React from "react";
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../services/operations/authAPI"
import logo from "../res/logo.jpg"
import RoomEasy from "../res/RoomEasy.png"


export default function Header() {
  const { token } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.profile)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  

  return (
    <header className="flex flex-wrap gap-5 justify-center items-center bg-white min-h-[100px]">
      <div className="flex flex-wrap gap-5 h-max items-center self-stretch my-auto min-w-[240px] max-md:max-w-full">
        <div className="flex gap-4 justify-center items-center h-max self-stretch my-auto text-2xl font-semibold text-neutral-600 ">
          <Link to="/">
          <img
            loading="lazy"
            src={RoomEasy}
            alt=""
            className="object-cover  self-stretch my-auto w-20 aspect-[0.96]"
          /></Link>
          
          <div className="self-stretch my-auto">Room Easy</div>
        </div>
       
      </div>
      {/* //navigation */}
      <nav className="flex flex-wrap gap-5 items-center self-stretch my-auto text-base font-semibold min-w-[240px] max-md:max-w-full">
        <div className="flex flex-wrap gap-1.5 items-center self-stretch my-auto min-w-[240px] text-zinc-800 max-md:max-w-full">
          <div className="flex gap-5 items-start self-stretch my-auto min-w-[240px]">
            <Link to="/">
              <div className="gap-2.5 self-stretch p-2 font-medium whitespace-nowrap">
                Home
              </div>
            </Link>
            <Link to="/about">
              <div className="gap-2.5 self-stretch p-2 font-medium whitespace-nowrap">
                About us
              </div>
            </Link>

            <Link to="/contact">
              <div className="gap-2.5 self-stretch p-2 font-medium whitespace-nowrap">
                Contact
              </div>
            </Link>
          </div>
          <div className="flex gap-5 justify-center items-center self-stretch my-auto whitespace-nowrap">
            {/* <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e412fc5c59e31bb283df994f5721382bbefb38ce5ec7019c705cfc701cd77dfd?apiKey=eb65262d43eb4a3b9768d399a79a5367&&apiKey=eb65262d43eb4a3b9768d399a79a5367"
              alt=""
              className="object-contain shrink-0 self-stretch my-auto w-px border border-gray-100 border-solid aspect-[0.03]"
            /> */}
            <div className="flex gap-0.5 items-center self-stretch my-auto">
              <div className="flex gap-0 items-start self-stretch my-auto bg-sky-500 rounded-lg">
                <div className="flex gap-0 items-center">
                  {token === null && (
                    <Link to="/login">
                      <div className="gap-2.5 self-stretch p-2 my-auto bg-red">
                        Login
                      </div>
                    </Link>
                  )}
                   {token !== null && (
                    <Link to="/dashboard/my-profile">
                      <div className="gap-2.5 self-stretch p-2 my-auto bg-red text-white ">
                      <span className="self-stretch my-auto">Dashboard</span>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* //submit property */}
      <Link to="/create-listing">
        <button className="flex gap-2 justify-center items-center self-stretch my-auto text-white bg-sky-500 min-h-[48px] rounded-[100px] w-[211px]">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cf1b41e14da131a426185789d964995c84ea15f28c9eef6671520499d0df795b?apiKey=eb65262d43eb4a3b9768d399a79a5367&&apiKey=eb65262d43eb4a3b9768d399a79a5367"
            alt=""
            className="object-contain shrink-0 self-stretch my-auto aspect-square w-[15px]"
          />
          <span className="self-stretch my-auto">Submit Property</span>
        </button>
      </Link>

      {/* profile  & logout*/}
      <Link to="/">
        {token !== null && (
          <img
            className="rounded-full h-7 w-7 object-cover"
            src={user?.image}
            alt="profile"
          />
        )}
      </Link>

      {token !== null  && (
        <Link to="/">
          <button
             onClick={() => {
              dispatch(logout(navigate))
            }}
            className="bg-richblack-800 py-[8px] px-[12px] rounded-[8px] border border-richblack-700"
          >
            Log Out
          </button>
        </Link>
      )}
      
    </header>
  );
}
