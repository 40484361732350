// src/components/ImageSlider.js
import React, { useState } from "react";
const apiUrl = process.env.REACT_APP_BASE_URL;

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="relative">
      <div className="overflow-hidden h-[250px]">
        <img
          src={images[currentIndex]}
          width="300"
          height="200"
          alt={`Property image ${apiUrl}`}
          className="w-full h-full object-cover"
        />
      </div>
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded"
      >
        &#10094;
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded"
      >
        &#10095;
      </button>
    </div>
  );
};

export default ImageSlider;
