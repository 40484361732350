import { toast } from "react-hot-toast"
// import { setLoading } from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector"
import { listingEndpoints } from "../apis"
import {profileEndpoints} from "../apis"

const {
  DELETE_LISTING_API,
  GET_ALL_LISTING_API,
  CREATE_LISTING_API,
  GET_RANDOM_LISTING_API,
  GET_CITIES_API,
  GET_LISTING_TYPE_API,
  EDIT_LISTING_API,
  FETCH_LISTING,
  SEARCH_API,
  UPLOAD_IMAGE_API,
  CREATE_RATING_API,
  HIDE_LISTING_API,
  UNHIDE_LISTING_API,
  GET_LOCALITIES_API,
  DELETE_IMAGE_API
} = listingEndpoints

const {
  GET_USER_LISTING,
  GET_USER_HIDDEN_LISTING
} = profileEndpoints;

export const getAllListing = async () => {
  const toastId = toast.loading("Loading...")
  let result = []
  try {
    const response = await apiConnector("GET", GET_ALL_LISTING_API)
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch listing data")
    }
    result = response?.data?.data
  } catch (error) {
    console.log("GET_ALL_LISTING_APIAPI ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)

  return result
}

export const fetchListing = async (listingId) => {
 
    let result = []
    const toastId = toast.loading("Loading...")
    // console.log(listingId);
    const data =listingId;
    try {
      const response = await apiConnector(
        "GET",
        FETCH_LISTING,data,null,
        {listingId}
        
      )
      console.log("GET LISTING LISTING RESPONSE............", response)
      if (!response?.data?.success) {
        throw new Error("Could Not Fetch the Listings")
      }
      result = response?.data?.listingDetails
      // console.log(result)
    } catch (error) {
      console.log("GET_LISTING ERROR............", error)
      toast.error(error.message)
    }
    toast.dismiss(toastId)
    return result
};


// fetching the available course categories
export const getUserListing = async (token) => {
  let result = []
  const toastId = toast.loading("Loading...")
  try {
    const response = await apiConnector(
      "GET",
      GET_USER_LISTING,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    )
    console.log("GET USER LISTING RESPONSE............", response)
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch User Listings")
    }
    result = response?.data?.data
  } catch (error) {
    console.log("GET_USER_LISTING ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)
  return result
}


// add the listing details
export const addListingDetails = async (Data, token) => {
  let result = null
  // for (let [key, value] of formData.entries()) {
  //   console.log(`${key}: ${value}`);
  

  const toastId = toast.loading("Loading...")
  try {
    const response = await apiConnector("POST",  CREATE_LISTING_API, Data, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    })
    console.log("CREATE LISTING API RESPONSE............", response)
    if (!response?.data?.success) {
      throw new Error("Could Not Add Listing Details")
    }
    toast.success("Listing Details Added Successfully") 
    result = response?.data?.data
  } catch (error) {
    console.log("CREATE LISTING API ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)
  return result
}



// delete a listing
export const deleteListing= async (listingId,token) => {
  const toastId = toast.loading("Loading...")
  try {
    const response = await apiConnector("DELETE", DELETE_LISTING_API,null, {
      Authorization: `Bearer ${token}`,
    },{listingId})
    console.log("DELETE LISTING  API RESPONSE............", response)
    if (!response?.data?.success) {
      throw new Error("Could Not Delete Listing")
    }
    toast.success("Listing Deleted")
  } catch (error) {
    console.log("DELETE LISTING API ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)
}

// get random listing
export const getRandomListing = async () => {
  const toastId = toast.loading("Loading...")
  let result = []
  try {
    const response = await apiConnector("GET",  GET_RANDOM_LISTING_API)
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch listing data")
    }
    result = response?.data?.data
    // console.log(" GET_RANDOM_LISTING_API rsponse............", result)
  } catch (error) {
    console.log(" GET_RANDOM_LISTING_API ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)

  return result
}

// create a rating 
export const createRating = async (data, token) => {
  const toastId = toast.loading("Loading...")
  let success = false
  try {
    const response = await apiConnector("POST", CREATE_RATING_API, data, {
      Authorization: `Bearer ${token}`,
    })
    console.log("CREATE RATING API RESPONSE............", response)
    if (!response?.data?.success) {
      throw new Error("Could Not Create Rating")
    }
    toast.success("Rating Created")
    success = true
  } catch (error) {
    success = false
    console.log("CREATE RATING API ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)
  return success
}


export const  getCities =async ()=>{
  let result = null;
  try{
     
    const response = await apiConnector("GET",GET_CITIES_API)
    
    // console.log("GET CITIES API RESPONSE" ,response)

    if(!response?.data?.success)
    {
      throw new Error("failed to get cities")
    }
    result= response?.data?.data;
  }
  catch(error){
    console.log("GET CITIES API ERROR",error)
  }
  return result
}

export const  getListingType =async ()=>{
  let result = null;
  try{
     
    const response = await apiConnector("GET",GET_LISTING_TYPE_API)

    if(!response?.data?.success)
    {
      throw new Error("failed to get listing types")
    }
    result= response?.data?.data;
  }
  catch(error){
    console.log("GET LISTING TYPES  API ERROR",error)
  }
  return result
}

export const searchListing = async (query)=>{
  let result = null;
  const data = query;
console.log(data)

  try{
    const response = await apiConnector("GET",SEARCH_API,null,null,{data});
    // console.log("this is response",response)

    if(!response?.data?.success)
    {
      throw new Error("failed to get search data");
    }

    result = response?.data?.properties;
  }
  catch(error)
  {
    console.log(" SEARCH_API ERROR ",error);
  }
  return result;
}

export const uploadImages = async (formData) =>{
//getting result 
let result = null;
console.log(formData);
try{
  const response = await apiConnector("POST", UPLOAD_IMAGE_API,formData)

  if(!response?.data?.success)
    {
      throw new Error("failed to get image urls");
    }

  result = response?.data?.url;

} 
catch(error)
{
console.log("UPLOAD IMAGE API ERROR",error)
}
 return result; 
}


export const hideProperty = async(listingId)=> {
  let result = null;
 
  try{
    const response = await apiConnector("PUT",HIDE_LISTING_API,listingId,null,{listingId});

    if(!response?.data?.success)
    {
      throw new Error("Failed to hide property")
    }
    result=response
  }
  catch(error){
    console.log("HIDE_LISTING_API error",error);
  }
  return result
}

export const unhideProperty = async(listingId)=> {
  let result = null;
 
  try{
    const response = await apiConnector("PUT",UNHIDE_LISTING_API,null,null,{listingId});

    if(!response?.data?.success)
    {
      throw new Error("Failed to unhide property")
    }
    result=response
  }
  catch(error){
    console.log("UNHIDE_LISTING_API error",error);
  }
  return result
}

export const getUserHiddenListing= async (token) => {
  let result = []
  const toastId = toast.loading("Loading...")
  try {
    const response = await apiConnector(
      "GET",
      GET_USER_HIDDEN_LISTING,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    )
    console.log("GET USER LISTING RESPONSE............", response)
    if (!response?.data?.success) {
      throw new Error(response?.data?.message)
    }
    result = response?.data?.data
  } catch (error) {
    console.log("GET_USER_LISTING ERROR............", error)
    toast.error(error.message)
  }
  toast.dismiss(toastId)
  return result
}

export const updateListing = async(listingId,data,token)=> {
  let result = null;
 
  try{
    const response = await apiConnector("PUT",EDIT_LISTING_API,data, {
      Authorization: `Bearer ${token}`,
    },{listingId});

    if(!response?.data?.success)
    {
      throw new Error("Failed to unhide property")
    }
    result=response
  }
  catch(error){
    console.log("UNHIDE_LISTING_API error",error);
  }
  return result
}


export const  getLocalitiesByCity =async (selectedCity)=>{
  let result = null;
  
  try{
     
    const response = await apiConnector("GET",GET_LOCALITIES_API,null,null,{selectedCity})
    
    // console.log("GET CITIES API RESPONSE" ,response)

    if(!response?.data?.success)
    {
      throw new Error("failed to get localities")
    }
    result= response?.data?.localities
  }
  catch(error){
    console.log("GET LOCALITIES API ERROR",error)
  }
  return result
}

export const  deleteImage =async (url)=>{
  let result = null;
  console.log(url)
  try{
     
    const response = await apiConnector("DELETE",DELETE_IMAGE_API,url,null,{url})
    
    // console.log("GET CITIES API RESPONSE" ,response)
    result= response?.data?.localities
  }
  catch(error){
    console.log("DELETE_IMAGE_API ERROR",error)
  }
  return result
}


