// src/FeatureCard.js
import React from "react";

function FeatureCard({ icon, title, description }) {
  return (
    <article className="flex flex-col justify-center items-center bg-white rounded-xl shadow-lg transform transition-transform duration-500 hover:scale-105 min-h-[366px] min-w-[240px] w-[377px] p-6">
      <div className="flex flex-col justify-center items-center bg-blue-500 bg-opacity-20 h-[136px] min-h-[136px] rounded-full w-[136px] p-6">
        <img loading="lazy" src={icon} alt="" className="object-contain aspect-square w-[85px]" />
      </div>
      <div className="flex flex-col justify-center items-center mt-9 max-w-full text-gray-800 w-[354px] text-center">
        <h3 className="text-xl font-semibold mb-4">{title}</h3>
        <p className="text-base">{description}</p>
      </div>
    </article>
  );
}

export default FeatureCard;
