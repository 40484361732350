import React from "react";
import {Link} from "react-router-dom"

function FooterLegal() {
  return (
    <div className="text-white text-center mt-10">
      <p>&copy; 2024 Room Easy. All rights reserved.</p>
      <p>
        <Link to="/privacy-policy" className="underline">
          Privacy Policy
        </Link>{" "}
        |{" "}
        <Link to="/terms-of-service" className="underline">
          Terms And Conditions
        </Link>
      </p>
    </div>
  );
}

export default FooterLegal;
