import React from "react";
import Banner from "../res/Banner.png";
import paymentQR from "../res/paymentQR.jpg";
import WhatsappButton from "../components/common/WhatsappButton";

const Subscription = () => {
  return (
    <div>
      <div className="mx-auto w-full bg-zinc-300 h-[2px] pt-1"></div>
      <div className="container mx-auto px-4 py-8">
        {/* Banner Image */}
        <div className="text-center mb-8">
          <img
            src={Banner}
            alt="Room Easy Premium Banner"
            className="mx-auto"
            style={{ width: "600px", height: "250px" }}
          />
        </div>

        <h1 className="text-4xl font-bold mb-6">
          Unlock the Perfect Room: Subscribe to Room Easy Premium
        </h1>

        <h2 className="text-3xl font-semibold mb-4">What You Get:</h2>
        <ul className="list-disc list-inside mb-6">
          <li>
            <strong>Detailed property information:</strong> Get in-depth details
            about each property, including amenities, photos, and neighborhood
            information.
          </li>
        </ul>

        <p className="text-2xl font-semibold mb-6">
          For just ₹499, you can unlock a world of convenience and find the
          perfect place to call home.
        </p>

        <div className="text-center mb-6">
          <img
            src={paymentQR}
            alt="QR Code for Payment"
            className="w-64 h-64 mx-auto"
          />
          <p className="mt-4 text-lg">
            Pay ₹499 using this QR code, and send us a screenshot of the
            transaction on WhatsApp at +91 7276606908.
          </p>
        </div>

        <h2 className="text-3xl font-semibold mb-4">How to Pay?</h2>
        <ol className="list-decimal list-inside mb-6">
          <li>Take a screenshot of the QR Code.</li>
          <li>Open your Payment app.</li>
          <li>Click on "Scan QR Code".</li>
          <li>Click on the "Image" option.</li>
          <li>Select the image of the QR code.</li>
          <li>
            Pay <strong>₹499</strong>.
          </li>
          <li>
            Send a screenshot of the completed payment transaction on WhatsApp
            at +91 7276606908.
          </li>
        </ol>
        <div>
          <WhatsappButton />
        </div>
        <p className="text-xl font-bold mt-6 text-center">
          Thank you for subscribing to Room Easy Premium!
        </p>
      </div>
    </div>
  );
};

export default Subscription;
