const reviews = [
  {
    id: 1,
    name: "Rohan Lande",
    job: "Engineering Student",
    image: "https://res.cloudinary.com/dsx0kergo/image/upload/v1725648080/dfrhgdpm25bcxajmxzzk.jpg",
    text: "I love how easy it is to browse through different rooms on Room Easy.",
  },
  {
    id: 2,
    name: "Vikram Gavade",
    job: "Student",
    image: "https://res.cloudinary.com/dsx0kergo/image/upload/v1725647911/zlgncs6hvnjjulaqykgb.jpg",
    text: "Room Easy saved me so much time and stress when searching for a new place to live.",
  },
  {
    id: 3,
    name: "Abhay Shelke",
    job: "Student",
    image: "https://res.cloudinary.com/dsx0kergo/image/upload/v1725648133/mlbceiz9sycuhsjoxsvu.jpg",
    text: "I found my dream apartment on Room Easy at an amazing price!",
  },
  {
    id: 4,
    name: "Somnath Chavan",
    job: "Student",
    image: "https://res.cloudinary.com/dsx0kergo/image/upload/v1725648376/somnath-compressed_zartnf.jpg",
    text: "Room Easy made difficult task of finding roommate easiest!!",
  },
  {
    id: 5,
    name: " DN ",
    job: "MBA Student",
    image: "https://res.cloudinary.com/dsx0kergo/image/upload/v1725647709/qydmclerbgffgptmyxcl.jpg",
    text: "Room Easy is the best app for students looking for affordable housing.",
  },
];

export default reviews;