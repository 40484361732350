import { useState } from "react"
import { VscSignOut } from "react-icons/vsc"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { BiCollapse } from "react-icons/bi";
import { BiExpand } from "react-icons/bi";
import { sidebarLinks } from "../../data/dashboard-links"
import { logout } from "../../services/operations/authAPI"
import ConfirmationModal from "../common/ConfirmationModal"
import SidebarLink from "./SidebarLink"

export default function Sidebar() {  // Accept the isCollapsed prop
  const { user, loading: profileLoading } = useSelector(
    (state) => state.profile
  )
  const { loading: authLoading } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [confirmationModal, setConfirmationModal] = useState(null)
  const [isCollapsed, setIsSidebarCollapsed] = useState(true); 

  if (profileLoading || authLoading) {
    return (
      <div className={`grid h-[calc(100vh-3.5rem)] ${isCollapsed ? "min-w-[80px]" : "min-w-[220px]"} items-center border-r-[1px] border-r-richblack-700 bg-richblack-800`}>
        <div className="spinner"></div>
      </div>
    )
  }

  return (
    <>
      <div className={`flex h-[calc(100vh-3.5rem)] ${isCollapsed ? "min-w-[20px] " : "min-w-[220px]"} flex-col items-center border-r-[1px] border-r-richblack-700 bg-richblack-800 py-3 px-1`}>
      <button
              className=" bg-richblack-800 h-4 w-auto"
              onClick={() => setIsSidebarCollapsed(!isCollapsed)}
            >
              {isCollapsed ? <BiExpand /> : <BiCollapse />}
            </button>
        <div className="flex flex-col ">
          {sidebarLinks.map((link) => (
            <SidebarLink key={link.id} link={link} iconName={link.icon} isCollapsed={isCollapsed} /> 
          ))}
        </div>
        {!isCollapsed && (  // Hide the settings and logout when collapsed
          <>
            <div className="mx-auto mt-6 mb-6 h-[1px] w-10/12 bg-richblack-700" />
            <div className="flex flex-col">
           
              <button
                onClick={() =>
                  setConfirmationModal({
                    text1: "Are you sure?",
                    text2: "You will be logged out of your account.",
                    btn1Text: "Logout",
                    btn2Text: "Cancel",
                    btn1Handler: () => dispatch(logout(navigate)),
                    btn2Handler: () => setConfirmationModal(null),
                  })
                }
                className="px-8 py-2 text-sm font-medium text-richblack-300"
              >
                <div className="flex items-center gap-x-2">
                  <VscSignOut className="text-lg" />
                  <span>Logout</span>
                </div>
              </button>
            </div>
          </>
        )}
      </div>
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
    </>
  )
}
