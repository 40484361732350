import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios"; // Import axios
// import statesInIndia from "../data/statesData";
// import localityData from "../data/localityData";
// import citiesInIndia from "../data/indianCitiesData";
import { addListingDetails } from "../services/operations/listingOperations";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  uploadImages,
  deleteImage,
} from "../services/operations/listingOperations";
import locationData from "../data/locationData";
const apiUrl = process.env.REACT_APP_BASE_URL;

export default function CreateProperty() {
  const { token } = useSelector((state) => state.auth);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  const [localities, setLocalities] = useState([]);
  const navigate = useNavigate();



  const [Data, setFormData] = useState({
    title: "",
    description: "",
    offer_type: "",
    property_type: "",
    apartment_style: "",
    bathrooms: "",
    amenities: [],
    furnishing_status: "",
    available_from: "",
    rent: "",
    deposit: "",
    Lease_duration: "",
    phone: "",
    whatsapp_number: "",
    House_no: "",
    apartment: "",
    state: "",
    city: "",
    locality: "",
    pinCode: "",
    images: [],
  });

  const {
    title,
    description,
    offer_type,
    property_type,
    apartment_style,
    bathrooms,
    amenities,
    furnishing_status,
    available_from,
    rent,
    deposit,
    Lease_duration,
    phone,
    whatsapp_number,
    House_no,
    apartment,
    state,
    city,
    locality,
    pinCode,
    images,
  } = Data;

  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [uploadedImages, setUploadedImages] = useState([]);
  const [file, setFile] = useState("");

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    console.log(Data);
  };

  const handleStateChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    console.log(Data);
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity("");
    setCities(Object.keys(locationData[state] || {}));
  };

  const handleCityChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    console.log(Data);
    const city = e.target.value;
    setSelectedCity(city);
    setLocalities(locationData[selectedState][city] || []);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prev) => ({
      ...prev,
      amenities: checked
        ? [...prev.amenities, name]
        : prev.amenities.filter((amenity) => amenity !== name),
    }));
  };

  const handleDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!title) errors.title = "title is required.";
    if (!offer_type) errors.offer_type = "Offer type is required.";
    if (!property_type) errors.property_type = "Property type is required.";

    if (!apartment_style)
      errors.apartment_style = "Apartment style is required.";
    if (!bathrooms) errors.bathrooms = "Number of bathrooms is required.";

    if (!furnishing_status)
      errors.furnishing_status = "Furnishing status is required.";
    if (!available_from)
      errors.available_from = "Available from date is required.";
    if (!rent) errors.rent = "Rent amount is required.";
    if (!deposit) errors.deposit = "Deposit amount is required.";
    if (!Lease_duration) errors.Lease_duration = "Lease duration is required.";
    if (!phone) errors.phone = "Phone number is required.";
    if (!whatsapp_number)
      errors.whatsapp_number = "WhatsApp number is required.";
    if (!House_no) errors.House_no = "House number is required.";
    if (!apartment) errors.apartment = "Apartment name is required.";
    if (!state) errors.state = "State is required.";
    if (!city) errors.city = "City is required.";
    if (!locality) errors.locality = "Locality is required.";
    if (!pinCode) errors.pinCode = "Pin Code is required.";
    if (!amenities.length === 0)
      errors.amenities = "At least one amenity must be selected.";

    setFormErrors(errors);

    // if (formData.images.length < 1) {
    //   setError("You must upload at least one image.");
    //   return;
    // }
    const formData = new FormData();
    formData.append("title", Data.title);
    formData.append("description", Data.description);
    formData.append("offer_type", Data.offer_type);
    formData.append("property_type", Data.property_type);
    formData.append("apartment_style", Data.apartment_style);
    formData.append("bathrooms", Data.bathrooms);
    formData.append("furnishing_status", Data.furnishing_status);
    formData.append("available_from", Data.available_from);
    formData.append("rent", Data.rent);
    formData.append("deposit", Data.deposit);
    formData.append("Lease_duration", Data.Lease_duration);
    formData.append("phone", Data.phone);
    formData.append("whatsapp_number", Data.whatsapp_number);
    formData.append("House_no", Data.House_no);
    formData.append("apartment", Data.apartment);
    formData.append("state", Data.state);
    formData.append("city", Data.city);
    formData.append("locality", Data.locality);
    formData.append("pinCode", Data.pinCode);

    // For arrays, iterate over the items and append each one individually
    amenities.forEach((amenity) => formData.append("amenities", amenity));
    images.forEach((image) => formData.append("images", image));
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      setLoading(true);
      const response = await addListingDetails(formData, token);

      const result = await response.json();
      setLoading(false);

      if (result.success) {
        navigate(`/dashboard/my-listing`);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
      setLoading(false);
    }
  };
  const onDrop = useCallback((acceptedFiles) => {
    const uploadedUrls = [];
    setLoading(true);
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = async () => {
        const base64String = reader.result;
        try {
          const response = await uploadImages({
            image: base64String,
          });
          uploadedUrls.push(response);
          if (uploadedUrls.length === acceptedFiles.length) {
            setUploadedImages(uploadedUrls);
            setFormData((prev) => ({
              ...prev,
              images:Data.images.concat(uploadedUrls),
            }));
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      };

      reader.readAsDataURL(file);
    });
    setLoading(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    console.log("Form Data updated: ", Data);
    console.log(uploadedImages);
  }, [Data,uploadedImages]);

  return (
    <main className="max-w-4xl mx-auto p-5">
      <h1 className="text-3xl font-bold text-center mb-10">
        List Your Property
      </h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* Property Title */}
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Property Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter the property title"
            />
          </div>

          {/* Property Description */}
          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Property Description
            </label>
            <textarea
              id="description"
              name="description"
              rows="4"
              value={description}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter the property description"
            ></textarea>
          </div>

          {/* Offer Type */}
          <div>
            <label className="block text-sm font-medium">Offerd For</label>
            <select
              name="offer_type"
              // value={offer_type}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">select</option>
              <option value="Girls">Girls</option>
              <option value="Boys">Boys</option>
              <option value="Boys/Girls">Boys/Girls</option>
              <option value="Boys">Family</option>
            </select>
            {formErrors.offer_type && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.offer_type}
              </p>
            )}
          </div>

          {/* Property Type */}
          <div>
            <label className="block text-sm font-medium">Property Type</label>
            <select
              name="property_type"
              value={property_type}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">select</option>
              <option value="Flat">Flat</option>
              <option value="Hostel">Hostel</option>
              <option value="PG">PG</option>
            </select>
            {formErrors.property_type && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.property_type}
              </p>
            )}
          </div>

          {/* Apartment Style */}
          <div>
            <label className="block text-sm font-medium">Apartment Type</label>
            <select
              name="apartment_style"
              // value={apartment_style}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">select</option>
              <option value="1BHK">1BHK</option>
              <option value="2BHK">2BHK</option>
              <option value="1RK">1RK</option>
              <option value="1Room">Room</option>
              <option value="Hostel">Shared Room</option>
            </select>
            {formErrors.apartment_style && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.apartment_style}
              </p>
            )}
          </div>

          {/* Bathrooms */}
          <div>
            <label className="block text-sm font-medium">Bathroom</label>
            <select
              name="bathrooms"
              value={bathrooms}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">select</option>
              <option value="Common">Common</option>
              <option value="Attached">Attached</option>
            </select>
            {formErrors.bathrooms && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.bathrooms}
              </p>
            )}
          </div>

          {/* Furnishing Status */}
          <div>
            <label className="block text-sm font-medium">
              Furnishing Status
            </label>
            <select
              name="furnishing_status"
              value={furnishing_status}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">select</option>
              <option value="Furnished">Furnished</option>
              <option value="Semi Furnished">Semi Furnished</option>
              <option value="Unfurnished">Unfurnished</option>
            </select>
            {formErrors.furnishing_status && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.furnishing_status}
              </p>
            )}
          </div>

          {/* Available From */}
          <div>
            <label className="block text-sm font-medium">Available From</label>
            <input
              name="available_from"
              type="date"
              value={available_from}
              onChange={handleDateChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formErrors.available_from && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.available_from}
              </p>
            )}
          </div>

          {/* Rent */}
          <div>
            <label className="block text-sm font-medium">Rent (₹)</label>
            <input
              name="rent"
              type="number"
              value={rent}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formErrors.rent && (
              <p className="text-red-500 text-sm mt-1">{formErrors.rent}</p>
            )}
          </div>

          {/* Deposit */}
          <div>
            <label className="block text-sm font-medium">Deposit (₹)</label>
            <input
              name="deposit"
              type="number"
              value={deposit}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formErrors.deposit && (
              <p className="text-red-500 text-sm mt-1">{formErrors.deposit}</p>
            )}
          </div>

          {/* Lease Duration */}
          <div>
            <label className="block text-sm font-medium">
              Lease Duration (Months)
            </label>
            <input
              name="Lease_duration"
              type="number"
              value={Lease_duration}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formErrors.Lease_duration && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.Lease_duration}
              </p>
            )}
          </div>

          {/* Phone */}
          <div>
            <label className="block text-sm font-medium">Phone</label>
            <input
              name="phone"
              type="tel"
              value={phone}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formErrors.phone && (
              <p className="text-red-500 text-sm mt-1">{formErrors.phone}</p>
            )}
          </div>

          {/* WhatsApp Number */}
          <div>
            <label className="block text-sm font-medium">WhatsApp Number</label>
            <input
              name="whatsapp_number"
              type="tel"
              value={whatsapp_number}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formErrors.whatsapp_number && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.whatsapp_number}
              </p>
            )}
          </div>

          {/* House No */}
          <div>
            <label className="block text-sm font-medium">House Number</label>
            <input
              name="House_no"
              type="text"
              value={House_no}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formErrors.House_no && (
              <p className="text-red-500 text-sm mt-1">{formErrors.House_no}</p>
            )}
          </div>

          {/* Apartment */}
          <div>
            <label className="block text-sm font-medium">Apartment</label>
            <input
              name="apartment"
              type="text"
              value={apartment}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formErrors.apartment && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.apartment}
              </p>
            )}
          </div>

          {/* State */}
          <div>
            <label className="block text-sm font-medium">State</label>
            <select
              name="state"
              value={state}
              onChange={handleStateChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">select </option>
              {Object.keys(locationData).map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {formErrors.state && (
              <p className="text-red-500 text-sm mt-1">{formErrors.state}</p>
            )}
          </div>

          {/* City */}
          <div>
            <label className="block text-sm font-medium">City</label>
            <select
              name="city"
              value={city}
              onChange={handleCityChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">select </option>
              {cities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
            {formErrors.city && (
              <p className="text-red-500 text-sm mt-1">{formErrors.city}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium">Locality</label>
            <select
              name="locality"
              value={locality}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="">select </option>
              {localities.map((locality) => {
                return (
                  <option key={locality} value={locality}>
                    {locality}
                  </option>
                );
              })}
            </select>
            {formErrors.state && (
              <p className="text-red-500 text-sm mt-1">{formErrors.state}</p>
            )}
          </div>
          {/* Pin Code */}
          <div>
            <label className="block text-sm font-medium">Pin Code</label>
            <input
              name="pinCode"
              type="text"
              value={pinCode}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {formErrors.pinCode && (
              <p className="text-red-500 text-sm mt-1">{formErrors.pinCode}</p>
            )}
          </div>

          {/* Amenities */}
          <div className="sm:col-span-2">
            <label className="block text-sm font-medium">Amenities</label>
            <div className="mt-1 grid grid-cols-2 sm:grid-cols-3 gap-2">
              {[
                "Water Purifier",
                "Gym",
                "Parking",
                "WiFi",
                "Security",
                "AC",
                "Washing Machine",
                "Cleaner",
                "Water Heater/Solar",
                "cot",
                "bed",
                "table",
              ].map((amenity) => (
                <div key={amenity} className="flex items-center">
                  <input
                    name={amenity}
                    type="checkbox"
                    checked={amenities.includes(amenity)}
                    onChange={handleCheckboxChange}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label htmlFor={amenity} className="ml-2 text-sm">
                    {amenity}
                  </label>
                </div>
              ))}
            </div>
            {formErrors.amenities && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.amenities}
              </p>
            )}
          </div>

          <div className="text-red-300">
            <p>
              {" "}
              You can only Uplaod upto 6 images. Add surrounding area images for
              better reach. do not display your name in images{" "}
            </p>
          </div>
          {/* images */}
          <div>
            <div
              {...getRootProps()}
              className="border-dashed border-4 border-gray-400 p-6 text-center mb-4"
            >
              <div className="text-red-300">
                Images Must Not Contain Name or Contact Info
              </div>
              <input {...getInputProps()} />
              <p>click to select files,select multiple images at once</p>
            </div>

            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
              {uploadedImages.map((url, index) => (
                <div>
                  <img
                    key={index}
                    src={url}
                    alt={url}
                    className="w-full h-auto object-cover"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-center mx-auto">
            <button
              type="submit"
              className="px-4 py-2 mx-auto bg-sky-500 text-white rounded-md shadow-sm hover:bg-indigo-700"
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Property"}
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="text-red-600 text-center mt-4">{error}</div>
          )}
        </div>
      </form>
    </main>
  );
}
