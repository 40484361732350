const BASE_URL = "https://king-prawn-app-we29d.ondigitalocean.app/api/v1"  //deployed backend base url


// AUTH ENDPOINTS
export const endpoints = {
  SENDOTP_API: BASE_URL + "/auth/sendotp",
  SIGNUP_API: BASE_URL + "/auth/signup",
  LOGIN_API: BASE_URL + "/auth/login",
  RESETPASSTOKEN_API: BASE_URL + "/auth/reset-password-token",
  RESETPASSWORD_API: BASE_URL + "/auth/reset-password",
}

// PROFILE ENDPOINTS
export const profileEndpoints = {
  GET_USER_DETAILS_API: BASE_URL + "/profile/getUserDetails",
  GET_USER_LISTING:BASE_URL +'/listing/getUserListings',
  GET_USER_HIDDEN_LISTING:BASE_URL + '/listing/getUserHiddenListings'
}
// LISTING ENDPOINTS
export const listingEndpoints = {
  GET_ALL_LISTING_API: BASE_URL + "/listing/get",
  GET_RANDOM_LISTING_API: BASE_URL + "/listing/getRandomListings",
  EDIT_LISTING_API: BASE_URL + "/listing/updateListing",
  FETCH_LISTING: BASE_URL + "/listing/getListingDetails",
  HIDE_LISTING_API:BASE_URL+ '/listing/hide',
  UNHIDE_LISTING_API:BASE_URL+ '/listing/unhide',
  CREATE_LISTING_API: BASE_URL + "/listing/createListing",
  DELETE_LISTING_API: BASE_URL + "/listing/deleteListing",
  GET_CITIES_API:BASE_URL + "/listing/getListingCities",
  GET_LOCALITIES_API:BASE_URL + "/listing/getLocalitiesByCity",
  GET_LISTING_TYPE_API:BASE_URL+"/listing/getListingTypes",
  SEARCH_API:BASE_URL + "/listing/getSearchListings",
  UPLOAD_IMAGE_API:BASE_URL + "/listing/uploadImages",
  DELETE_IMAGE_API:BASE_URL + "/listing/deleteImage"
}

// RATINGS AND REVIEWS
export const ratingsEndpoints = {
  REVIEWS_DETAILS_API: BASE_URL + "/listing/getReviews",
}
// CONTACT-US API
export const contactusEndpoint = {
  CONTACT_US_API: BASE_URL + "/reach/contact",
}

// SETTINGS PAGE API
export const settingsEndpoints = {
  UPDATE_DISPLAY_PICTURE_API: BASE_URL + "/profile/updateDisplayPicture",
  UPDATE_PROFILE_API: BASE_URL + "/profile/updateProfile",
  CHANGE_PASSWORD_API: BASE_URL + "/auth/changepassword",
  DELETE_PROFILE_API: BASE_URL + "/profile/deleteProfile",
}
