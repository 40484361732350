import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../services/operations/authAPI";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    contact: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const { contact, password } = formData;

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(login(contact, password, navigate));
  };

  return (
    <div>
      <div className="mx-auto w-full bg-zinc-300 h-[2px] pt-1"></div>
      <main className=" w-full mx-auto flex items-center justify-center  bg-gray-100 ">
        <div className="flex flex-col lg:flex-row max-w-6xl w-full bg-white rounded-lg shadow-lg h-[750px]">
          <section className="flex-1 p-10 flex flex-col justify-center items-center rounded-lg">
            <div className="text-center">
              <h2 className="text-3xl font-bold text-sky-500 mb-8">Login</h2>
              <form onSubmit={handleOnSubmit} className="space-y-8">
                <div>
                  {/* <button
                  type="button"
                  className="flex items-center justify-center w-full bg-white border border-gray-300 rounded-lg py-3 px-6 text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-sky-500"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f001c1c9a227057c15697c40e9649b6c7d22770c08326b8dbe82a959d35166d?apiKey=446edaa23c6048939c8f81a264470296"
                    alt="Google Icon"
                    className="w-6 h-6 mr-3"
                  />
                  Login with Google
                </button> */}
                </div>

                <div className="relative flex items-center w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-4">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/edc563c012397b1eb449743d986b5687158c7f6961cf09aefcf4f098af8e17b6?apiKey=446edaa23c6048939c8f81a264470296"
                      alt="Email Icon"
                      className="w-6 h-6"
                    />
                  </div>
                  <input
                    type="tel"
                    placeholder="Mobile Number"
                    id="contact"
                    name="contact"
                    value={contact}
                    onChange={handleOnChange}
                    className="w-full pl-12 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500"
                  />
                </div>

                <div className="relative flex items-center">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-4">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1da5064c4e8e6a03260f6e5e6a9b7464abcd4c7412f51935097a7c3d15b3e258?apiKey=446edaa23c6048939c8f81a264470296"
                      alt="Password Icon"
                      className="w-6 h-6"
                    />
                  </div>
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={handleOnChange}
                    className="w-full pl-12 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500"
                  />
                </div>

                <div className="flex justify-between items-center">
                  {/* <a href="#" className="text-sm text-sky-500 hover:underline">
                  Lost Your Password?
                </a> */}
                </div>

                <button
                  type="submit"
                  // disabled={loading}
                  className="w-full py-3 px-6 bg-sky-500 text-white font-semibold rounded-lg hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500"
                >
                  {/* {loading ? "Logging in..." : "Login"} */}Login
                </button>

                <div className="text-center text-sm text-gray-600">
                  Don't have an account?{" "}
                  <Link to="/signup" className="text-sky-500 hover:underline">
                    Register
                  </Link>
                </div>
              </form>
            </div>
          </section>

          <section className="hidden lg:flex flex-1 items-center justify-center bg-sky-500 rounded-r-lg relative overflow-hidden">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/05a820123648a4d09b53fc7e13ec9ef5a876c08c2099094c836da91b296a1c2e?apiKey=446edaa23c6048939c8f81a264470296"
              alt="Welcome Image"
              className="absolute inset-0 object-cover w-full h-full"
            />
            <div className="relative text-center text-white z-10">
              <h1 className="text-5xl font-bold mb-6">Welcome Back!</h1>
              <p className="text-xl">Please log in to continue.</p>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
