// src/PropertySlider.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropertyCard from "./Dashboard/listing/PropertyCard";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRandomListing } from "../services/operations/listingOperations";

export default function PropSlider() {
  
  const [sliderData, setSliderData] = useState([]);
  const { user } = useSelector((state) => state.profile);

  useEffect(() => {
    const fetchRandomListing = async () => {
      const result = await getRandomListing();

      if (result) {
        setSliderData(result);
      }
    };
    fetchRandomListing();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mx-auto flex ">
      <div className="container mx-auto py-14">
        <h2 className="text-3xl font-bold mb-5 text-center pb-14">
          Featured Properties
        </h2>
        <Slider {...settings}>
          {sliderData.map((listing, index) => {
            return (
              <div key={listing._id} className="px-3">
                <PropertyCard key={listing._id} 
                    offerfor={listing.offer_type} 
                    index={index}
                    locality={listing.locality}
                    city={listing.city}
                    price={listing.rent}    
                    type={listing.property_type}
                    userimage={listing?.image}
                    user={user?.firstName} 
                    images={listing.images}
                    link={`/listing-details/${listing.id}`}
                    listing={listing}
                    />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
