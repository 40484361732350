import React from "react";
import Footer from "../components/common/Footer";
import { Helmet } from 'react-helmet';


// import ReviewSlider from "../components/Common/ReviewSlider"
import ContactDetails from "../components/ContactUsPage/ContactDetails";
import ContactForm from "../components/ContactUsPage/ContactForm";

const Contact = () => {
  return (
    <div>
      <Helmet>
      <title>Contact Room Easy: Get in Touch with Our Team</title>
      <meta name="description" content="Have questions or need assistance? Contact our friendly support team for help. Find our contact information and office location." />
      <meta name="keywords" content="contact room easy, contact us, support, help, room easy, shared accommodation, India" />
    </Helmet>
      <div className="mx-auto w-full bg-zinc-300 h-[2px] pt-1"></div>
      <div className="container mx-auto px-4 py-8 max-w-7xl">
        <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
        <p className="mb-4">
          At Room Easy, we understand the importance of clear communication.
          Whether you have questions about finding the perfect roommate,
          navigating our platform, or simply want to connect with our team,
          we're here to help!
        </p>

        <h2 className="text-2xl font-semibold mt-6 mb-2">
          Here are the ways you can reach us:
        </h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Phone:</strong> +91 7276606908 (We're available during
            business hours: Monday-Friday, 9:00 AM - 6:00 PM IST)
          </li>
          <li>
            <strong>Email:</strong>{" "}
            <a href="mailto:contact@roomeasy.net" className="text-blue-600">
              contact@roomeasy.net
            </a>{" "}
            (Our friendly support team will respond to your email inquiries
            within 24 hours)
          </li>
          <li>
            <strong>Social Media:</strong> Follow us on{" "}
            <a href="https://t.me/room_easy_net" className="text-blue-600">
              Telegram @room_easy_net
            </a>{" "}
            for updates, tips, and community engagement! You can also discover
            our content on{" "}
            <strong>Facebook, Instagram, X, Threads, TikTok & YouTube</strong>{" "}
            at <span className="text-blue-600">@room_easy_net</span>.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-6 mb-2">
          Looking for Our Headquarters?
        </h2>
        <p className="mb-4">
          While we primarily operate online, you can find our administrative
          office at:
        </p>
        <address className="mb-4 not-italic">
          <strong>Room Easy</strong>
          <br />
          Dattawadi, Pune 411030 (India)
        </address>
        <p className="mb-4">
          <strong>Please note:</strong> Visits to our headquarters are by
          appointment only. For in-person assistance, we recommend scheduling a
          call or sending us an email.
        </p>

        <p className="text-xl font-semibold mt-6">
          We look forward to hearing from you!
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
