import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon from react-icons

const WhatsappButton = ({ contact }) => {
  const message = `Hello, I am interested in your subscription: ${contact}. Could you please provide details?`;
  const whatsappUrl = `https://wa.me/${7276606908}?text=${encodeURIComponent(message)}`;

  return (
    <a
      href={whatsappUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600 transition duration-300 ease-in-out"
    >
      <FaWhatsapp className="text-2xl mr-2" /> {/* WhatsApp Icon */}
      <span className="text-lg font-semibold">Contact via WhatsApp</span>
    </a>
  );
};

export default WhatsappButton;

