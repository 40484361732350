
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { fetchListing } from "../services/operations/listingOperations";
import WhatsAppButton from "../components/common/WhatsappButton";
import Footer from "../components/common/Footer";
import { CiPhone } from "react-icons/ci";

export default function UserListingDetails() {

    const { token } = useSelector((state) => state.auth);
    const [loading, setLoaing] = useState(true);
    const { membership } = useSelector((state) => state.profile);
    const [listing, setProperty] = useState({ images: [] }); // Initialized as an object with an empty array for images
    const { listingId } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchProperty = async () => {
        if (!token) {
          navigate("/login");
        } else {
          try {
            const res = await fetchListing(listingId);
            setProperty(res || []); // Set property data
            console.log("Fetched property details:", res);
          } catch (error) {
            console.error("Could not fetch listing details:", error);
          }
        }
        setLoaing(false);
      };
      fetchProperty();
    }, [listingId, membership, token, navigate]);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    return (
      <div>
        <div className="mx-auto w-full bg-zinc-300 h-[2px] pt-1"></div>
        <div className="App bg-gray-50 min-h-screen  ">
          {/* Header */}
          <div className=" mx-auto p-6 bg-white shadow-md  max-w-7xl mt-9 border">
            <div className="max-w-7xl mx-auto flex flex-col md:flex-row  space-y-4 md:space-y-0">
              <div className="mx-auto">
                <h1 className="text-3xl font-bold text-gray-800">
                  {listing.title}
                </h1>
                <p className="text-gray-600 mt-1">
                  {listing.House_no}, {listing.apartment}, {listing.locality},{" "}
                  {listing.city}, {listing.pinCode}
                </p>
              </div>
            </div>
          </div>
  
          {/* Property Images Section */}
          <div className="p-6 max-w-7xl mx-auto">
            <h2 className="text-2xl  text-gray-800 mb-4">Property Images</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 border">
              {listing.images.length > 0 ? (
                listing.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`image${index}`}
                    className="w-full h-64 object-cover rounded-lg shadow-md"
                  />
                ))
              ) : (
                <p>No images available.</p>
              )}
            </div>
          </div>
  
          {/* About Property */}
          <div className="p-6 max-w-7xl mx-auto border bg-white rounded-lg shadow-md my-8">
            <h2 className="text-2xl text-gray-800 mb-4">About the Property</h2>
            <p className="text-gray-700">{listing.description}</p>
          </div>
  
          {/* Room Details */}
          <div className="p-6 max-w-7xl mx-auto bg-white rounded-lg shadow-md my-8 border">
            <h2 className="text-2xl text-gray-800 mb-4">Property Details</h2>
            <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mt-2">{listing.offer_type}</p>
              </div>
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mt-2">
                  {listing.property_type} || {listing.apartment_style}
                </p>
              </div>
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mt-2">{listing.furnishing_status}</p>
              </div>
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mt-2">{listing.property_type}</p>
              </div>
              <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mt-2">Rent -{listing.rent}</p>
              </div>
            </div>
          </div>
  
          {/* Amenities */}
          <div className="p-6 max-w-7xl mx-auto bg-white rounded-lg shadow-md my-8">
            <h2 className="text-2xl  text-gray-800 mb-4">Amazing Amenities</h2>
            <div className="flex flex-cols justify-center items-center">
              <div className=" mx-auto grid grid-cols-3 md:grid-cols-auto gap-6 text-center">
                {listing.amenities.length > 0 ? (
                  listing.amenities.map((amenity, index) => (
                    <div className="text-center bg-gray-100 p-6 rounded-lg shadow-md ">
                      <span className="text-gray-600 mt-2 ">{amenity}</span>
                    </div>
                  ))
                ) : (
                  <p>No images available.</p>
                )}
              </div>
            </div>
          </div>
  
          {/* contact section  */}
          <div className=" flex flex-col justify-center items-center  p-6 max-w-7xl mx-auto rounded-lg shadow-md my-8 border">
            <h2 className="text-xl mb-2">Contact Owner For More Details</h2>
            <div className="flex flex-col md:flex-row justify-center md:justify-start items-center gap-6 p-6">
              <div className="text-md mb-2 bg-grey-100">
                <span>Phone:</span> {listing.phone}
              </div>
              <WhatsAppButton contact={listing.whatsapp_number} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
  )
}
