import React from 'react'
import NewsletterSubscription from '../footer/NewsletterSubscription';
import FooterImage from '../footer/FooterImage';
import FooterLegal from "../footer/FooterLegal"
import FooterLinks from "../footer/FooterLinks";
import RoomEasy from "../../res/RoomEasy.png"

export default function Footer() {
  return (
    <footer className="flex overflow-hidden flex-col text-base">
      <div className="flex flex-col justify-center items-center p-20 w-full bg-slate-800 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col w-full max-w-[1233px] max-md:max-w-full">
          <div className="flex gap-4 justify-center items-center self-start text-2xl font-semibold text-white">
            <img loading="lazy" src={RoomEasy} className="object-contain shrink-0 self-stretch my-auto w-11 aspect-[0.96]" alt="Apna PG logo" />
            <h1 className="self-stretch my-auto">Room Easy</h1>
          </div>
          <p className="self-end mr-48 tracking-wide text-white max-md:mr-2.5">
            Get the freshest news from us
          </p>
          <div className="flex flex-wrap gap-5 justify-between mt-6 w-full tracking-wide max-md:max-w-full">
            <FooterLinks />
            <NewsletterSubscription />
          </div>
          <FooterImage />
          <FooterLegal />
        </div>
      </div>
    </footer>
  )
}
