const featureData = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d8844b0dfbb05dd7b54d0f63d885f68e258c9542df97128027fe6052e75007eb?apiKey=446edaa23c6048939c8f81a264470296&&apiKey=446edaa23c6048939c8f81a264470296",
      title: "Find Your Room at Your Fingertips",
      description: "Roomeasy is your one-stop solution for hassle-free room hunting in India."
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6ecafbe6461feee8085a29961f8fb78e7560935fe248c57ef9aedd6c6f91dc92?apiKey=446edaa23c6048939c8f81a264470296&&apiKey=446edaa23c6048939c8f81a264470296",
      title: "Wide Range Of Properties",
      description: "From cozy PGs to spacious flats, we've got you covered"
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e82a0786b291847d18ab6ebfdacb0cee5ca0550b20ced06cb544d33bec8594d9?apiKey=446edaa23c6048939c8f81a264470296&&apiKey=446edaa23c6048939c8f81a264470296",
      title: "What You Want at Your Budget",
      description: " Find your dream room without breaking the bank"
    }
  ];
export default featureData;