import React from 'react'
import MyListingCard from '../components/Dashboard/listing/MyListingCard'
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getUserHiddenListing } from '../services/operations/listingOperations'
import { useEffect,useState } from 'react'

function HiddenListing() {
  
    const { user } = useSelector((state) => state.profile)
    const { token } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const [listings, setListings] = useState([])
  
    useEffect(() => {
      const fetchListing = async () => {
        const result = await getUserHiddenListing(token)
        if (result) {
          setListings(result || [])
        }
      }
      fetchListing()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

  
    return (
      <div>
          <div className="container mx-auto p-4">
              <h1 className="text-2xl font-bold mb-6">My Listings</h1>
              
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {listings.map((listing,index) => {
                    return (
                      <div>
                      <MyListingCard key={listing._id} 
                      listingId={listing._id}
                      offerfor={listing.offer_type} 
                      index={index}
                      locality={listing.locality}
                      city={listing.city}
                      price={listing.rent}    
                      type={listing.property_type}
                      userimage={user?.image}
                      user={user?.firstName} 
                      images={listing.images}
                      hide={listing.hide}             
                      />
             
                      </div>
                      )})}
              </div>
              
          </div>
        
      </div>
    );
}

export default HiddenListing
