import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sendOtp, signUp } from "../services/operations/authAPI";
import { setSignupData } from "../slices/authSlice";
import { toast } from "react-hot-toast";

export default function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Initialize the useForm hook
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Handle Form Submission
  const onSubmit = (data) => {
    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      toast.error("Passwords Do Not Match");
      return;
    }

    const signupData = { ...data };
    dispatch(setSignupData(signupData));

    // Dispatch signup action
    dispatch(
      signUp(
        data.firstName,
        data.lastName,
        data.contact,
        password,
        confirmPassword,
        navigate
      )
    );

    // Reset form fields after successful submission
    reset();
  };

  return (
    <div>
      <div className="mx-auto w-full bg-zinc-300 h-[2px] pt-1"></div>
      <div className="mx-auto flex flex-col justify-center items-center  w-[90%] py-14 ">
      <div className="flex flex-col md:flex-row max-w-6xl w-full bg-white rounded-lg shadow-lg overflow-hidden h-[750px] border">
        <div className="md:w-1/2 p-10 flex flex-col justify-center">
          <h2 className="text-3xl font-bold text-sky-500 mb-8 text-center">
            Register
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="relative mb-4">
              <input
                type="text"
                placeholder="First Name"
                {...register("firstName", {
                  required: "First Name is required",
                })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-500"
              />
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6b7bbc8214e87147f7579862d8623b91c97c76a75816ea6c82fa2999da13c2e?apiKey=446edaa23c6048939c8f81a264470296"
                alt=""
                className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
              {errors.firstName && (
                <p className="text-red-500 text-sm mt-2">
                  {errors.firstName.message}
                </p>
              )}
            </div>

            <div className="relative mb-4">
              <input
                type="text"
                placeholder="Last Name"
                {...register("lastName", { required: "Last Name is required" })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-500"
              />
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6b7bbc8214e87147f7579862d8623b91c97c76a75816ea6c82fa2999da13c2e?apiKey=446edaa23c6048939c8f81a264470296"
                alt=""
                className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
              {errors.lastName && (
                <p className="text-red-500 text-sm mt-2">
                  {errors.lastName.message}
                </p>
              )}
            </div>

            {/* <div className="relative mb-4">
              <input
                type="email"
                placeholder="Email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-500"
              />
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3be07356cc210854b3f3c71770eab7d4acb832cb07c41b71907c7652bce8e983?apiKey=446edaa23c6048939c8f81a264470296"
                alt=""
                className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-2">{errors.email.message}</p>
              )}
            </div> */}
            <div className="relative mb-4">
              <input
                type="tel"
                placeholder="Mobile Number"
                {...register("contact", {
                  required: "Contact number is required",
                  pattern: {
                    value: /^[0-9]{10}$/, // Regex pattern for a 10-digit mobile number
                    message: "Invalid mobile number. It should be 10 digits.",
                  },
                })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-500"
              />
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3be07356cc210854b3f3c71770eab7d4acb832cb07c41b71907c7652bce8e983?apiKey=446edaa23c6048939c8f81a264470296"
                alt=""
                className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
              {errors.contact && (
                <p className="text-red-500 text-sm mt-2">
                  {errors.contact.message}
                </p>
              )}
            </div>

            <div className="relative mb-4">
              <input
                type="password"
                placeholder="Password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters",
                  },
                })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-500"
              />
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/760ad6688b49a856e4e4e2e631483940e736d93b038ac11b64c654ca4238d032?apiKey=446edaa23c6048939c8f81a264470296"
                alt=""
                className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
              {errors.password && (
                <p className="text-red-500 text-sm mt-2">
                  {errors.password.message}
                </p>
              )}
            </div>
            <p className="text-red-500 text-sm mt-2">
                  Password must be at least 8 characters
                </p>

            <div className="relative mb-4">
              <input
                type="password"
                placeholder="Confirm Password"
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === watch("password") || "Passwords do not match",
                })}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-500"
              />
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/afe4eb67b5ca4486da5dd4dd9faf8a081be0b08c131de3c37fb75198a7498971?apiKey=446edaa23c6048939c8f81a264470296"
                alt=""
                className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-sm mt-2">
                  {errors.confirmPassword.message}
                </p>
              )}
            </div>

            <button
              type="submit"
              className="w-full py-3 mt-6 bg-sky-500 text-white font-semibold rounded-lg hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500"
            >
              Sign Up
            </button>
          </form>
          <p className="text-center mt-4">
            Already have an account?{" "}
            <Link to="/login" className="text-blue-500 hover:underline">
              Login
            </Link>
          </p>
        </div>
        <div
          className="md:w-1/2 bg-cover bg-center"
          style={{
            backgroundImage:
              "url('https://cdn.builder.io/api/v1/image/assets/TEMP/baddebef390bd22639f529deb8f183b88ecffc91c3bb2f47670236f89a81418a?apiKey=446edaa23c6048939c8f81a264470296')",
          }}
        >
          <div className="flex items-center justify-center h-full bg-black bg-opacity-50 p-10">
            <h1 className="text-white text-4xl font-bold">Create an account</h1>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
