import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  getCities,
  getListingType,
  getLocalitiesByCity
} from "../services/operations/listingOperations";

export default function Search() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [localities, setLocalities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result1 = await getCities();
      setCities(result1 || []);

      const result2 = await getListingType();
      setPropertyType(result2 || []);
    };

    fetchData();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm();

  const selectedCity = watch("city");

  useEffect(() => {
    if (selectedCity) {
      const fetchLocalities = async () => {
       
        const result = await getLocalitiesByCity(selectedCity);
       // Debugging line
        setLocalities(result || []); // Ensure `localities` is always an array
        // setValue("locality", ""); // Reset locality when city changes
      };

      fetchLocalities();
    }
  }, [selectedCity, setValue]);

  const onSubmit = async (data) => {
    const queryParams = new URLSearchParams(data).toString();
    navigate(`/listing?${queryParams}`);
  };

  return (
    <div className="p-4">
      <section className="flex flex-col bg-white rounded-lg shadow-lg p-6">
        <form
          className="flex flex-wrap gap-4 items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Property Type Dropdown */}
          <div className="flex flex-col w-full max-w-xs">
            <div className="flex gap-3 items-center py-3 px-4 rounded-lg border border-gray-300">
              <label htmlFor="propertyType" className="text-gray-700">
                Property Type
              </label>
              <select
                id="propertyType"
                className="ml-2 bg-white border-none text-gray-700 focus:outline-none flex-1"
                {...register("property_type", {
                  required: "Property type is required",
                })}
              >
                <option value="">Select type</option>
                {propertyType.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
            {errors.property_type && (
              <p className="text-red-500 text-sm mt-1">
                {errors.property_type.message}
              </p>
            )}
          </div>

          {/* City Dropdown */}
          <div className="flex flex-col w-full max-w-xs">
            <div className="flex gap-3 items-center py-3 px-4 rounded-lg border border-gray-300">
              <label htmlFor="city" className="text-gray-700">
                City
              </label>
              <select
                id="city"
                className="ml-2 bg-white border-none text-gray-700 focus:outline-none flex-1"
                {...register("city", {
                  required: "City is required",
                })}
              >
                <option value="">Select City</option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
            {errors.city && (
              <p className="text-red-500 text-sm mt-1">
                {errors.city.message}
              </p>
            )}
          </div>

          {/* Locality Dropdown */}
          <div className="flex flex-col w-full max-w-xs">
            <div className="flex gap-3 items-center py-3 px-4 rounded-lg border border-gray-300">
              <label htmlFor="locality" className="text-gray-700">
                Locality
              </label>
              <select
                id="locality"
                className="ml-2 bg-white border-none text-gray-700 focus:outline-none flex-1"
                {...register("locality", {
                  required: "Locality is required",
                })}
              >
                <option value="">Select Locality</option>
                {Array.isArray(localities) && localities.length > 0 ? (
                  localities.map((locality, index) => (
                    <option key={index} value={locality}>
                      {locality}
                    </option>
                  ))
                ) : (
                  <option value="">No localities available</option>
                )}
              </select>
            </div>
            {errors.locality && (
              <p className="text-red-500 text-sm mt-1">
                {errors.locality.message}
              </p>
            )}
          </div>

          {/* Search Button */}
          <button
            className="ml-4 bg-sky-500 text-white py-3 px-6 rounded-lg hover:bg-sky-600 transition-all w-full max-w-[161px]"
            type="submit"
            disabled={loading}
          >
            {loading ? "Searching..." : "Search"}
          </button>
        </form>
      </section>
    </div>
  );
}
