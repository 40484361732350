import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useState } from "react"; // Import useState
import Sidebar from "../components/Dashboard/Sidebar";

function Dashboard() {
  const { loading: profileLoading } = useSelector((state) => state.profile);
  const { loading: authLoading } = useSelector((state) => state.auth);
  // Add state to control sidebar collapse

  if (profileLoading || authLoading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="mx-auto w-full bg-zinc-300 h-[2px] pt-1"></div>
      <div className="relative flex min-h-[calc(100vh-3.5rem)]">
      {/* <button
              className="p-2 bg-richblack-800 text-white"
              onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
            >
              {isSidebarCollapsed ? "Expand Menu" : "Collapse Menu"}
            </button> */}
        <Sidebar />{" "}
        {/* Pass the collapse state as a prop */}
        <div className="h-[calc(100vh-3.5rem)] flex-1 overflow-auto">
          <div className="mx-auto w-11/12 max-w-[1000px] py-5">
           
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
