
  
  const locationData={
    
        AndhraPradesh: {
          Visakhapatnam: ["Madhurawada", "Gajuwaka", "MVP Colony", "Dwaraka Nagar"],
          Vijayawada: ["Benz Circle", "Patamata", "Poranki", "Krishna Lanka"]
        },
        ArunachalPradesh: {
          Itanagar: ["Vivek Vihar", "Ganga Market", "Naharlagun", "Chandranagar"]
        },
        Assam: {
          Guwahati: ["Paltan Bazaar", "Dispur", "Beltola", "G.S. Road"]
        },
        Bihar: {
          Patna: ["Boring Road", "Kankarbagh", "Patliputra Colony", "Rajendra Nagar"]
        },
        Chhattisgarh: {
          Raipur: ["Civil Lines", "Shankar Nagar", "Tatibandh", "Amanaka"]
        },
        Goa: {
          Panaji: ["Miramar", "Dona Paula", "Patto", "Altinho"]
        },
        Gujarat: {
          Ahmedabad: ["Navrangpura", "Bopal", "Satellite", "Maninagar"],
          Surat: ["Adajan", "Vesu", "Katargam", "Dumas"]
        },
        Haryana: {
          Gurgaon: ["DLF Phase 1", "Sohna Road", "Golf Course Road", "Sector 56"],
          Faridabad: ["Sector 15", "Sector 16", "NIT", "Greenfield Colony"]
        },
        HimachalPradesh: {
          Shimla: ["Mall Road", "Kufri", "Chotta Shimla", "Lakkar Bazaar"]
        },
        Jharkhand: {
          Ranchi: ["Kanke Road", "Harmu", "Morabadi", "Lalpur"]
        },
        Karnataka: {
          Bengaluru: ["Whitefield", "Koramangala", "Indiranagar", "Marathahalli"],
          Mysuru: ["Gokulam", "Vijayanagar", "Saraswathipuram", "Chamundi Hill"]
        },
        Kerala: {
          Kochi: ["Marine Drive", "Fort Kochi", "Edappally", "Vyttila"],
          Thiruvananthapuram: ["Kowdiar", "Pattom", "Kazhakootam", "Vazhuthacaud"]
        },
        MadhyaPradesh: {
          Indore: ["Vijay Nagar", "Palasia", "Rau", "Bhawarkua"],
          Bhopal: ["Arera Colony", "Hoshangabad Road", "Kolar Road", "Shahpura"]
        },
        Maharashtra: {
          Mumbai: [
            "Andheri", "Bandra", "Juhu", "Dadar", "Borivali", "Goregaon", "Malad",
            "Kurla", "Colaba", "Mulund", "Powai", "Chembur", "Worli", "Lower Parel",
            "Vile Parle"
          ],
          Pune:[
           "Agalambe", "Agarkar Nagar", "Akurdi", "Alandi", "Alephata", "Amanora Park Town", "Ambegaon", "Ambegaon Budruk", "Ambegaon Pathar", "Amboli", "Anand Nagar", "Ane", "Ashok Nagar", "Ashoka Nagar", "Ashtapur", "Askarwadi", "Aundh", "Aundh Gaon",
"Bakori", "Balaji Nagar", "Balewadi", "Balewadi Phata", "Baner", "Baner Gaon", "Baramati", "Bavdhan", "Bavdhan Khurd", "Bawada", "Bhagyashree Nagar", "Bhawani Peth", "Bhekrai Nagar", "Bhor", "Bhosale Nagar", "Bhosari", "Bhugaon", "Bhukum",
"Bhumkar Nagar", "Bhusari Colony", "Bibwewadi", "Bope", "Bopodi", "Budhwar Peth", "Bund Garden", "Camp", "Chakan", "Chandan Nagar", "Chandani Chowk", "Chande", "Chandkhed", "Charholi", "Charholi Budruk", "Charholi Khurd", "Chikhali", "Chimbali",
"Chinchwad", "Chinchwad Gaon", "Chintamani Nagar", "Chourainagar", "Choviswadi", "Clover Park", "COEP", "Cummins College", "Dahanukar Colony", "Dange Chowk", "Dapodi", "Dapoli", "Dattanagar", "Dattawadi", "Daund", "Deccan Gymkhana", "Dehu", "Dhankawadi", 
"Dhanore", "Dhanori", "Dhayari", "Dhayari Phata", "DIT Pimpri", "Dighi", "DPCE", "DYPSE", "DYPCOE", "DYPIEMR", "DYPSOET", "EON Free Zone", "Erandwane", "Fatima Nagar", "Fursungi", "Gahunje", "Ganesh Nagar", "Ganesh Peth", "Ganeshkhind", "Ganga Dham", 
"GHRCEM", "GHRIET", "Ghorpade Peth", "Ghorpadi", "Ghorpuri", "Ghotawade", "Gokhale Nagar", "Gokul Nagar", "GSMCOE Baner", "GSMCOE Wagholi", "Gultekdi", "Guru Nanak Nagar", "Guruganesh Nagar", "Guruwar Peth", "Hadapsar", "Hadapsar Gaon", 
"Hadapsar Industrial Estate", "Handewadi", "Hingne Khurd", "Hinjawadi", "I²IT", "Ideal Colony", "ICOER", "ICEM", "Indapur", "Indira Nagar", "Induri", "ISBM COE", "Jambhul", "Jejuri", "JSPM NTC", "JSBSIT", "JSCOE", "JSPM NTC", "Junnar", "Kalas", 
"Kalewadi", "Kalyani Nagar", "Kalyani Nagar Annexe", "Kamshet", "Kanhe", "Kanhur Mesai", "Karjat", "Karve Nagar", "Kasarwadi", "Kasba Peth", "Kaspate Vasti", "Katarkhadak", "Katraj", "Kavade Mala", "Kedagaon", "Keshav Nagar", "Ketkawale", 
"Khadakwasla", "Khadki", "Khamundi", "Kharadi", "Khed", "Khed Shivapur", "Kirkatwadi", "Kiwale", "Kolhewadi", "Kondanpur", "Kondhapuri", "Kondhawe Dhawade", "Kondhwa", "Kondhwa Budruk", "Kondhwa Khurd", "Koregaon", "Koregaon Park", "Kothrud",
"Kutwal Colony", "Landewadi", "Lavale", "Lavasa", "Lohegaon", "Lonavala", "Loni Kalbhor", "Lonikand", "Lulla Nagar", "Maan", "Madhav Nagar", "Magarpatta City", "Mahalunge", "Maharshi Nagar", "Mahatma Phule Peth", "Malegaon", "Malkapur", "Malwadi",
"Mamurdi", "Manchar", "Mandai", "Mangalwar Peth", "Manik Baug", "Manjri", "Markal", "Market Yard", "Marunji", "Masulkar Colony", "Maval", "Mhada Colony", "MCOE", "MES COE", "MIT AOE", "Model Colony", "Mohammed Wadi", "Mohan Nagar", "Mokarwadi", 
"Moledina", "Moshi", "Moshi Phata", "Moshi Pradhikaran", "Mugawade", "Mukund Nagar", "Mulshi", "Mundhwa", "Nana Peth", "Nande", "Nanded", "Narayan Peth", "Narayangaon", "Narhe", "Nasrapur", "Navi Peth", "NBN STIC", "Nere", "Nerhe", "New Sangvi", 
"NIBM", "NIBM Annexe", "Nigdi", "Nilakh", "North Hadapsar", "Pachane", "Padmavati", "Padvi", "Panchgani", "Pandhari Nagar", "Panshet", "Pargaon", "Parvati", "Parvati Darshan", "Parvati Gaon", "Pashan", "Pasure", "Patil Nagar", "Paud", "Pawna Nagar", 
"PCCOE", "PCCOE Ravet", "PDEACOE", "Phursungi", "PICT", "Pimple Gurav", "Pimple Nilakh", "Pimple Saudagar", "Pimpoli", "Pimpri", "Pimpri Chinchwad", "Pingori", "Pirangut", "Pisoli", "Pradhikaran", "Punawale", "Pune Cantonment", "Purandar", 
"Rahatani", "Rajgurunagar", "Ram Nagar", "Rambaug Colony", "Ramtekdi", "Range Hill Estate", "Ranjangaon", "Rasta Peth", "Ravet", "Raviwar Peth", "Revenue Colony", "RMDSITC", "Sadashiv Peth", "Sadhu Vaswani Chowk", "Sahakar Nagar", "Sainath Nagar", 
"Sakore Nagar", "Salisbury Park", "Salunkhe Vihar", "Sambhaji Nagar", "Sanaswadi", "Sanewadi", "Sangamvadi", "Sangvi", "Sanjay Park", "Sarola", "Sasane Nagar", "Saswad", "Satav Nagar", "Saykarwadi", "Sayyed Nagar", "Shalimar Chowk", "Shaniwar Peth", 
"Shankar Kalat Nagar", "Shastri Nagar", "Shetphal Haveli", "Shewalwadi", "Shikrapur", "Shinde Vasti", "Shirgaon", "Shirur", "Shirwal", "Shivaji Nagar", "Shivane", "Shivapur", "Shreehans Nagar", "Shukrawar Peth", "Siddartha Nagar", "Sinhagad", 
"SKNCOE", "Solapur", "Somatane", "Somwar Peth", "Sopan Baug", "Sukh Sagar Nagar", "Supe", "Sus", "Swargate", "SVPM COE", "Talawade", "Talegaon", "Talegaon Dabhade", "Talegaon Dhamdhere", "Taljai", "Talwade", "Tathawade", "Thergaon", "Theur", 
"Thite Nagar", "Tingre Nagar", "TSSMS VIT", "Tukaram Nagar", "Tulaja Bhawani Nagar", "Tulapur", "Tungarli", "Uday Baug", "Undri", "Uravade", "Urse", "Uruli", "Uruli Devachi", "Uruli Kanchan", "Uttam Nagar", "Vadgaon", "Vadgaon Budruk", 
"Vadgaon Sheri", "Vanaz", "Varasgaon", "Varve Khurd", "Veerbhadra Nagar", "Velhe", "Velu", "Vetal Nagar", "Vidyanagar", "Viman Nagar", "Vishal Nagar", "Vishnu Dev Nagar", "Vishrantwadi", "VIT", "Vithalwadi", "Vitthal Nagar", "VPKBIET", "Wadaki", 
"Wadebolai", "Wadegaon", "Wagholi", "Wai", "Wakad", "Wakadewadi", "Walhe", "Walvekar Nagar", "Wanowrie", "Wanwadi", "Warje", "WCE", "Wireless Colony", "Yashwant Nagar", "Yerawada", "Yewalewadi", "Yogeshwari Nagar","Zadgaon", "Zambre", "Zarewadi", "Zari", "Zenda Chowk"


          
          ]
          ,
          Nagpur: [
            "Dharampeth", "Ramdaspeth", "Civil Lines", "Sadar", "Manish Nagar",
            "Pratap Nagar", "Mankapur", "Besa", "Laxmi Nagar", "Seminary Hills",
            "Jaripatka", "Wardha Road", "Khare Town", "Sitabuldi", "Shankar Nagar"
          ],
          Nashik: [
            "Panchavati", "Gangapur Road", "College Road", "Indira Nagar", "Satpur",
            "Cidco", "Makhmalabad", "Pathardi Phata", "Deolali", "Ashok Nagar",
            "Dwarka", "Jail Road", "Ambad", "Meri Colony", "Adgaon"
          ],
          Aurangabad: [
            "CIDCO", "Samarth Nagar", "Garkheda", "Nirala Bazar", "Waluj", "Hudco",
            "Shahgunj", "Sutgirni Chowk", "Jalna Road", "Mondha", "Bansilal Nagar",
            "Khadkeshwar", "Satara Parisar", "Beed Bypass", "Padegaon"
          ],
          Thane: [
            "Ghodbunder Road", "Kopri", "Vartak Nagar", "Manpada", "Majiwada", "Kalwa",
            "Naupada", "Wagle Estate", "Bhiwandi", "Dombivli", "Kalyan", "Mira Road",
            "Balkum", "Kasara", "Ulhasnagar"
          ],
          NaviMumbai: [
            "Vashi", "Nerul", "Belapur", "Kharghar", "Airoli", "Ghansoli", "Seawoods",
            "Panvel", "Kamothe", "Ulwe", "Turbhe", "Sanpada", "CBD Belapur",
            "Kopar Khairane", "Dronagiri"
          ],
          Kolhapur: [
            "Shahupuri", "Laxmipuri", "Rajarampuri", "Tarabai Park", "Ujalaiwadi",
            "Kasba Bawada", "Karveer Nagar", "Rankala", "Kalamba", "Shivaji Peth",
            "Gur Market", "Nagala Park", "Sadar Bazar", "Pratibha Nagar", "Mangalwar Peth"
          ],
          Solapur: [
            "Sangole", "Kegaon", "Hotgi Road", "Akkalkot Road", "Siddheshwar Peth",
            "Railway Lines", "Vijapur Road", "Jule", "Sakhar Peth", "Murarji Peth",
            "Bhavani Peth", "Midc", "Shelgi", "Navi Peth", "Murarji Peth"
          ],
          Akola: [
            "Jatharpeth", "Tapadia Nagar", "Murtizapur", "Ramdas Peth", "Dabki Road",
            "Gorakshan Road", "Old City", "Khadan", "Mangrulpir Road", "Akot",
            "Umri Pragane Balapur", "Ganesh Nagar", "Shivani", "Kapila Nagar",
            "Tilak Road"
          ],
          Ahmednagar: [
            "Savedi", "Shirdi", "Pathardi", "Nalanda Nagar", "Sarjepura",
            "Burudgaon Road", "Bhingar", "Darewadi", "Jamkhed", "Kedgaon",
            "Maliwada", "Belapur", "Supa", "Ghodegaon", "Vijay Nagar"
          ],
          Chandrapur: [
            "Ram Nagar", "Ballarpur", "Babupeth", "Gandhi Chowk", "Tukum", "Nagpur Road",
            "Mul Road", "Durgapur", "Warora", "MIDC", "Ganj Ward", "Pathanpura",
            "Khutala", "Mool", "Maharana Pratap Nagar"
          ],
          Beed: [
            "Barshi Road", "Shivaji Chowk", "Karanja", "Kaij", "Ambajogai", "Ashti",
            "Dharur", "Majalgaon", "Gevrai", "Nagar Road", "Talkhed", "Dindrud",
            "Wadwani", "Kata Road", "Sawargaon"
          ],
          Ratnagiri: [
            "Alore", "Dapoli", "Chiplun", "Lanja", "Rajapur", "Khed", "Mandangad",
            "Ratnagiri", "Khedshi", "Guhagar", "Sangameshwar", "Pali", "Velas", "Ambet",
            "Chiplun"
          ]
        },
        Manipur: {
          Imphal: ["Thangal Bazar", "Uripok", "Lamphelpat", "Singjamei"]
        },
        Meghalaya: {
          Shillong: ["Laitumkhrah", "Police Bazar", "Nongthymmai", "Rynjah"]
        },
        Mizoram: {
          Aizawl: ["Zarkawt", "Chanmari", "Bawngkawn", "Sikulpuikawn"]
        },
        Nagaland: {
          Kohima: ["High School Area", "Razhu Point", "PR Hill", "Meriema"]
        },
        Odisha: {
          Bhubaneswar: ["Saheed Nagar", "Jayadev Vihar", "Patia", "Nayapalli"],
          Cuttack: ["Buxi Bazaar", "College Square", "Madhupatna", "Nua Bazaar"]
        },
        Punjab: {
          Ludhiana: ["Sarabha Nagar", "Model Town", "Civil Lines", "BRS Nagar"],
          Amritsar: ["Ranjit Avenue", "Lawrence Road", "Katra Ahluwalia", "Mall Road"]
        },
        Rajasthan: {
          Jaipur: ["Vaishali Nagar", "Malviya Nagar", "Mansarovar", "C-Scheme"],
          Jodhpur: ["Sardarpura", "Shastri Nagar", "Paota", "Ratanada"]
        },
        Sikkim: {
          Gangtok: ["MG Marg", "Tadong", "Deorali", "Indira Bypass"]
        },
        TamilNadu: {
          Chennai: ["T. Nagar", "Velachery", "Anna Nagar", "Adyar"],
          Coimbatore: ["R.S. Puram", "Saibaba Colony", "Race Course", "Gandhipuram"]
        },
        Telangana: {
          Hyderabad: ["Banjara Hills", "Madhapur", "Kukatpally", "Gachibowli"]
        },
        Tripura: {
          Agartala: ["Krishna Nagar", "Bardowali", "Indranagar", "Dhaleswar"]
        },
        Uttarakhand: {
          Dehradun: ["Rajpur Road", "Saharanpur Road", "Prem Nagar", "Race Course"]
        },
        UttarPradesh: {
          Lucknow: ["Gomti Nagar", "Hazratganj", "Aliganj", "Indira Nagar"],
          Kanpur: ["Swaroop Nagar", "Kakadeo", "Civil Lines", "Shastri Nagar"]
        },
        WestBengal: {
          Kolkata: ["Salt Lake", "Alipore", "Ballygunge", "Jadavpur"]
        }
      
      
  }
  
  export default locationData;