import React, { useState } from "react";
import ImageSlider from "../../detailsPage/ImageSlider";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  hideProperty,
  unhideProperty,
  deleteListing,
} from "../../../services/operations/listingOperations";
import { useNavigate } from "react-router-dom";
import { MdEditDocument } from "react-icons/md";
import { MdDelete } from "react-icons/md";
const imageurl = process.env.REACT_APP_BASE_URL;

export default function MyListingCard({
  offerfor,
  locality,
  listingId,
  city,
  images,
  price,
  type,
  user,
  index,
  hide,
  link,
  handleDelete,
}) {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const handleHide = async (listingId) => {
    try {
      const response = await hideProperty(listingId);

      if (!response?.data?.success) {
        throw new Error("failed to hide");
      }
      navigate("/dashboard/hidden-listing");
    } catch (error) {
      console.log(error);
    }
  };
  const handleUnhide = async (listingId) => {
    try {
      const response = await unhideProperty(listingId);

      if (!response?.data?.success) {
        throw new Error("failed to hide");
      }
      navigate("/dashboard/my-listing");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div key={index} className="px-2 ">
        <div className="border rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
          <ImageSlider images={images} />
          <div className="p-4 bg-white">
            <div className="flex justify-around items-center">
              <h3 className="text-xl font-semibold mb-2">
                Offer For {offerfor}
              </h3>
              <button
                onClick={() => {
                  handleDelete(listingId, navigate);
                }}
                className="ml-4 bg-sky-500 text-white py-2 px-2 rounded-lg hover:bg-green-600 transition-colors duration-300 ease-in-out  max-w-[161px] text-center"
              >
                <MdDelete />
              </button>
            </div>
           

            <p className="text-gray-600 text-lg">
              {locality}, {city}
            </p>
            <div className="flex justify-evenly mt-2">
              <p className="text-gray-600 font-bold">Rent - {price}</p>
              <p className="text-gray-600 font-bold">{type}</p>
            </div>
           

            <div className="flex items-center justify-evenly mt-3 mr-3">
              <img
                src={images[0]}
                alt={user}
                className="w-8 h-8 rounded-full mr-2"
              />
              <button
                onClick={() => {
                  {
                    hide ? handleUnhide(listingId) : handleHide(listingId);
                  }
                }}
                className="ml-4 bg-sky-500 text-white py-2 px-3 rounded-lg hover:bg-red-600 transition-colors duration-300 ease-in-out w-full max-w-[130px] text-center"
              >
                {hide ? "Unhide" : "Hide"}
              </button>
              <Link to={link}>
                <button className="ml-4 bg-sky-500 text-white py-2 px-3 rounded-lg hover:bg-green-600 transition-colors duration-300 ease-in-out w-full max-w-[161px] text-center">
                  View
                </button>
              </Link>
            </div>
            {/* <div className="flex justify-center items-center">
              <Link to={`/dashboard/my-listing`}>
                <button className="ml-4 bg-sky-500 text-white py-2 px-2 rounded-lg hover:bg-green-600 transition-colors duration-300 ease-in-out  max-w-[161px] text-center">
                  <MdEditDocument />
                </button>
              </Link>
             
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
