import React from "react";
import ImageSlider from "../../detailsPage/ImageSlider";
import { Link } from "react-router-dom";

export default function propertyCard({
  offerfor,
  locality,
  city,
  images,
  price,
  type,
  userimage,
  user,
  index,
  listing,
  link,
}) {
  return (
    <div key={index} className="px-2 w-[300px]">
      <div className="border rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105">
        <ImageSlider images={images} />
        <div className="p-4 bg-white">
          <h3 className="text-xl font-semibold mb-2">Offer For {offerfor}</h3>
          <p className="text-gray-600 text-lg">
            {locality}, {city}
          </p>
          <div className="flex justify-evenly mt-2">
            <p className="text-gray-600 font-bold">Rent - {price}</p>
            <p className="text-gray-600 font-bold">{type}</p>
          </div>

          <div className="flex items-center justify-evenly mt-3">
            <img
              src={images[0]}
              alt={user}
              className="w-8 h-8 rounded-full mr-2"
            />
            <Link to={link}>
              <button className="ml-4 bg-sky-500 text-white py-2 px-3 rounded-lg hover:bg-green-600 transition-colors duration-300 ease-in-out w-full max-w-[161px] text-center">
                View
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
