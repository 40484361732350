import './App.css';
import Header from "./components/Header"
import Home from "./pages/Home"
import Login from "./pages/Login"
import SignUp from "./pages/Signup"
import About from "./pages/About"
import PrivateRouter from "./components/PrivateRouter"
import Contact from "./pages/Contact"
import CreateListing from './pages/CreateListing';
import UpdateListing from './pages/UpdateListing';
import HiddenListing from './pages/HiddenListing';
import Listing from './pages/Listing';
import Dashboard from './pages/Dashboard';
import { useDispatch } from "react-redux"
import MyProfile from "./components/Dashboard/MyProfile"
import Settings from "./components/Dashboard/Settings/index"
import MyListing from "./pages/MyListing" 
import ListingDetails from './pages/ListingDetails';
import Subscription from "./pages/Subscription";
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions'
import UserListingDetails from './pages/UserListingDetails';
import { Helmet } from 'react-helmet';


// React Router
import { Route, Routes, useNavigate } from "react-router-dom"
import { getUserDetails } from "./services/operations/profileAPI"
import { useEffect } from "react"

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const { user } = useSelector((state) => state.profile)

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const token = JSON.parse(localStorage.getItem("token"))
      dispatch(getUserDetails(token, navigate))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="App m-5">
      <Header/>
      <Helmet>
      <title>Room Easy: Find Your Perfect Roommate and Living Space in India</title>
      <meta name="description" content="Find the ideal roommate and living space with Room Easy. Browse verified rooms, flats, PGs, and hostels in India. Easy, secure, and personalized." />
      <meta name="keywords" content="room easy, room easy.net, roomeasy, roomeasy.net rooms easy, easy room, hostel finder, pg finder, room finder, flat finder, roommate matching, shared accommodation, student housing, bachelor accommodation, living space, India" />
    </Helmet>
      
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/listing' element={<Listing />} />
        <Route path="/subscription" element ={<Subscription/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path='/listing-details/:listingId' element={<ListingDetails />} />
        <Route path='/user-listing-details/:listingId' element={<UserListingDetails />} />
        <Route path="/terms-of-service" element={<TermsAndConditions />} />
        <Route path='/dashboard/update-listing/:listingId' element={<UpdateListing />} />
       


        <Route element={<PrivateRouter />}>
          <Route path='/create-listing' element={<CreateListing />} />
        
          <Route path="/dashboard"
            element={ <Dashboard />
              }
  >
            <Route path="/dashboard/my-profile" element={<MyProfile />} />
            <Route path="/dashboard/Settings" element={<Settings />} />
            <Route path='/dashboard/create-listing' element={<CreateListing />} />
            <Route path='/dashboard/my-listing' element={<MyListing />} />
            <Route path='/dashboard/hidden-listing' element={<HiddenListing />} />
          
          </Route>
        </Route>
      </Routes>
      
    </div>
  );
}

export default App;
